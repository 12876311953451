import { NavItemType } from "common/types";

import { getBaseNavItems } from "../../pages/routes";
import useUnreadMessages from "./useUnreadMessages";

export default function useNavItems() {
  const { unreadMessagesCount } = useUnreadMessages();
  const navItems: NavItemType[] = [];

  getBaseNavItems().forEach((baseNavItem) => {
    const navItem: NavItemType = baseNavItem;
    if (navItem.path === "inbox") {
      navItem.notifications = unreadMessagesCount;
    }
    navItems.push(navItem);
  });

  return navItems;
}
