import React from "react";
import {
  CalendarX,
  DotsThreeVertical,
  Gear,
  Link as LinkIcon,
  Trash,
  UserCheck,
  UserMinus,
  type Icon,
} from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { WorkspaceAccount } from "common/types";
import RestrictedComponent from "common/components/RestrictedComponent";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";

import { AccountAction } from "../types";

interface ActionButtonProps {
  label: string;
  onClick: () => void;
  icon: Icon;
}

function ActionButton({ label, onClick, icon: Icon }: ActionButtonProps) {
  return (
    <Button
      variant="secondary-black"
      leftIcon={<Icon />}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {label}
    </Button>
  );
}

interface AccountActionsDropdownProps {
  account: WorkspaceAccount;
  setAction: (action: AccountAction) => void;
  openLicenseModal: () => void;
}

export default function AccountActionsDropdown({
  account: { license, state, id },
  setAction,
  openLicenseModal,
}: AccountActionsDropdownProps) {
  const isLicenseAvailable = useValidateAccountLicense(id);

  function handleLicense() {
    if (isLicenseAvailable) {
      setAction("license");
    } else {
      openLicenseModal();
    }
  }

  const dropdownMenuItems: {
    label: string;
    icon: Icon;
    onClick: () => void;
  }[] = [
    {
      onClick: () => setAction("settings"),
      icon: Gear,
      label: "Settings",
    },
    {
      onClick: handleLicense,
      label: `${license ? "Unassign" : "Assign"} seat`,
      icon: license ? UserMinus : UserCheck,
    },
  ];

  let actionComponent = null;

  if (license && license.expires_at) {
    const label = "Remove cancellation";
    const icon = CalendarX;
    const onClick = () => setAction("reactivate");

    actionComponent = (
      <ActionButton label={label} onClick={onClick} icon={icon} />
    );

    dropdownMenuItems.splice(1, 0, { label, icon, onClick });
  }

  // NOTE: Reconnect has a priority over removing a cancellation.
  if (state !== "LoggedIn") {
    const icon = LinkIcon;

    actionComponent = (
      <ActionButton
        label="Reconnect"
        onClick={() => setAction("reconnect")}
        icon={icon}
      />
    );

    dropdownMenuItems.splice(1, 0, {
      label: "Generate reconnect link",
      icon,
      onClick: () => setAction("reconnectLink"),
    });
  }

  return (
    <div className="flex items-center justify-end gap-x-3">
      {actionComponent}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="secondary-black"
            intent="iconOnly"
            onClick={(e) => e.stopPropagation()}
          >
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
          {dropdownMenuItems.map(({ icon: Icon, label, onClick }) => (
            <DropdownMenuItem key={label} onClick={onClick}>
              <Icon size={20} />
              {label}
            </DropdownMenuItem>
          ))}

          <RestrictedComponent
            disabledForRoles={[]}
            hiddenForRoles={["member"]}
          >
            {license ? (
              <Tooltip>
                <TooltipTrigger
                  className="my-1 flex w-full cursor-not-allowed gap-1 bg-black-200 px-4 py-2 text-button-14 text-black-400"
                  disabled
                >
                  <Trash size={20} /> Delete account
                </TooltipTrigger>

                <TooltipContent side="bottom">
                  You cannot delete an account with an assigned seat
                </TooltipContent>
              </Tooltip>
            ) : (
              <DropdownMenuItem
                onClick={() => setAction("delete")}
                className="text-red-500"
              >
                <Trash size={20} />
                Delete account
              </DropdownMenuItem>
            )}
          </RestrictedComponent>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
