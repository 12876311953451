import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import Loader from "common/components/Loader";

import useInvoices from "../../datahooks/useInvoices";
import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";

import NoInvoicesFound from "assets/images/empty-placeholders/no-invoices-found.png";

export default function Invoices() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { invoices, isLoadingInvoices, isInvoicesError, refetchInvoices } =
    useInvoices();

  if (isInvoicesError)
    return (
      <ErrorPlaceholder
        errorMessage="Could not load invoices"
        onRetry={refetchInvoices}
      />
    );

  if (isLoadingInvoices) return <Loader />;

  if (!invoices || !invoices.length) {
    return (
      <EmptyPlaceholder
        title="No invoices found"
        subtitle="There are no invoices to display"
        imageSrc={NoInvoicesFound}
      />
    );
  }

  const Table = isTabletOrDesktop ? DesktopTable : MobileList;

  return <Table invoices={invoices} />;
}
