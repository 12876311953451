import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  ArrowSquareOut,
  Envelope,
  GoogleChromeLogo,
  Icon,
  Lock,
} from "@phosphor-icons/react";
import { UseFormReturn } from "react-hook-form";
import clsx from "clsx";

import useGTM from "common/hooks/useGTM";
import { WorkspaceAccount } from "common/types";
import { HTTPError } from "common/helpers/HTTP";
import { Button } from "common/components/ui/Button";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import { LoginSchemaType } from "common/schemas";
import RenderIf from "common/components/RenderIf";
import useDebounce from "common/hooks/useDebounce";
import useProxies from "common/datahooks/useProxies";
import useChromeExtension from "common/hooks/useChromeExtension";
import ProxySelect from "common/components/ProxySelect";

import useWorkspaceAccountMutations from "../../datahooks/useWorkspaceAccountMutations";
import ExtensionLogin from "./ExtensionLogin";
import LoginMethodOption from "./LoginMethodOption";
import LinkedinInfo from "./LinkedinInfo";

const chromeExtensionId = import.meta.env.VITE_CHROME_EXTENSION_ID;

interface LoginProps {
  formReturn: UseFormReturn<LoginSchemaType>;
  account: WorkspaceAccount | undefined;
  onSuccess: () => void;
  setStep: (step: "thiswasme" | "pin") => void;
}

type LoginType = "email" | "extension";

interface LoginMethod {
  title: string;
  subtitle: string;
  icon: Icon;
  type: LoginType;
}

export default function Login({
  formReturn: {
    watch,
    setError,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  },
  account,
  onSuccess,
  setStep,
}: LoginProps) {
  const { sendEvent } = useGTM();
  const { login, isLoggingIn } = useWorkspaceAccountMutations();
  const shouldShowDelayMessage = useDebounce(isLoggingIn, 7000);
  const { suggestedCountryCode, countryCodes } = useProxies();
  const currentProxyLocation = watch("proxyLocation");

  const [isShowingEmailForm, setIsShowingEmailForm] = useState(false);
  const [selectedLoginMethod, setSelectedLoginMethod] =
    useState<LoginType>("email");

  const { isExtensionAvailable, isCheckingExtension } = useChromeExtension();

  const isChrome = navigator.userAgent.includes("Chrome");

  // Set the proxy location to the suggested country if it's not set
  useEffect(() => {
    if (
      !currentProxyLocation &&
      suggestedCountryCode &&
      countryCodes?.includes(suggestedCountryCode)
    ) {
      setValue("proxyLocation", suggestedCountryCode);
    }
  }, [suggestedCountryCode, countryCodes]);

  useEffect(() => {
    if (!isCheckingExtension) {
      setSelectedLoginMethod(
        !isExtensionAvailable && !isChrome ? "email" : "extension",
      );
    }
  }, [isExtensionAvailable, isCheckingExtension]);

  function onSubmit(loginData: LoginSchemaType) {
    login({ loginData })
      .then((response) => {
        if (response.loginResult === "PASS") {
          // Log to Sentry if full_name is missing
          if (!response.profile.full_name) {
            Sentry.setExtra("response_json", JSON.stringify(response));
            Sentry.captureMessage("Login - missing full_name");
          }

          onSuccess();
        } else if (response.loginResult === "CHALLENGE") {
          Sentry.setExtra("response_json", JSON.stringify(response));

          const { challengeType } = response;
          if (
            [
              "EmailPinChallenge",
              "SMSPinChallenge",
              "AuthenticatorAppChallenge",
            ].includes(challengeType)
          ) {
            Sentry.captureMessage("Login challenge");
            setStep("pin");
          } else if (challengeType === "CaptchaChallenge") {
            Sentry.captureMessage("Login - Captcha challenge");
            setError("password", {
              message: "Captcha challenge",
            });
          } else if (challengeType === "AppChallenge") {
            setStep("thiswasme");
          } else {
            Sentry.captureMessage("Login - Unknown challenge");
          }
        }
      })
      .catch((err) => {
        Sentry.setExtra("error", err);
        Sentry.setExtra("error_json", JSON.stringify(err));
        Sentry.captureMessage(
          `Login error${err instanceof HTTPError ? ` - ${err.type}` : ""}`,
        );

        sendEvent("connect_linkedin_account_fail");

        let errorMessage: string;
        if (
          err instanceof HTTPError &&
          err.serverMessage ===
            "logging into this account is forbidden by linkedin"
        ) {
          errorMessage = "Logging into this account is forbidden by LinkedIn";
        } else if (err.code === 409) {
          // this happens when the account is already connected to another workspace
          errorMessage = "Account already connected to another workspace";
        } else if (err.code === 401) {
          errorMessage = "Invalid email or password";
        } else {
          errorMessage = "Error connecting account";
        }

        setError("password", {
          message: errorMessage,
        });
      });
  }

  const loginMethods: LoginMethod[] = [
    {
      type: "extension",
      icon: GoogleChromeLogo,
      title: "Aimfox Chrome Extension",
      subtitle: "Connect to Aimfox using an existing LinkedIn login session",
    },
    {
      type: "email",
      icon: Envelope,
      title: "Email and password",
      subtitle: "Login your account via email & password",
    },
  ];

  const handleNextClick = () => {
    setIsShowingEmailForm(true);
  };

  const renderLoginForm = () => {
    if (selectedLoginMethod === "email") {
      return (
        <>
          <h2 className="mb-4 text-center text-headline-2xl-bold md:text-headline-xl-bold">
            {account ? "Re-connect" : "Connect"} your LinkedIn account
          </h2>

          <RenderIf condition={!!account}>
            <LinkedinInfo
              pictureUrl={account?.picture_url}
              fullName={account?.full_name}
              occupation={account?.occupation}
            />
          </RenderIf>

          <RenderIf condition={!account?.email}>
            <>
              <Label
                className={clsx("mb-1 ml-0.5", account && "mt-8 md:mt-4")}
                htmlFor="email"
              >
                Email address
              </Label>

              <Input
                id="email"
                variant="lg"
                placeholder="Email"
                error={errors.email?.message}
                {...register("email")}
              />
            </>
          </RenderIf>

          <Label
            className={clsx(
              "mb-1 ml-0.5 md:mt-4",
              account?.email ? "mt-8" : "mt-4",
            )}
            htmlFor="password"
          >
            Password
          </Label>

          <Input
            id="password"
            variant="lg"
            error={errors.password?.message}
            type="password"
            {...register("password")}
            placeholder="Enter password here"
          />

          <div className="mt-4 flex flex-col gap-y-2">
            <span className="text-black-700">Proxy (login location)</span>

            <ProxySelect
              countryCode={currentProxyLocation}
              onChange={(countryCode) => {
                setValue("proxyLocation", countryCode, {
                  shouldValidate: true,
                });
              }}
            />
          </div>

          {errors?.proxyLocation && (
            <span className="mt-1 text-caption-12-regular text-red-500">
              {errors.proxyLocation.message}
            </span>
          )}

          {/* Long Loading text */}
          <RenderIf condition={isLoggingIn && shouldShowDelayMessage}>
            <span className="mt-1 text-caption-12-regular text-black-500 animate-in fade-in-0">
              The login process is taking some time, please do not close or
              refresh this page
            </span>
          </RenderIf>

          {account ? (
            <>
              <Button
                className="mb-3 mt-8 md:mt-12"
                variant="primary-black"
                type="submit"
                isLoading={isLoggingIn}
              >
                Connect Account
              </Button>

              <div className="flex items-center justify-center gap-x-0.5">
                <Lock weight="fill" className="size-4 text-black-700" />

                <span className="text-caption-12-regular text-black-700">
                  Secured with TLS 1.3 encryption
                </span>
              </div>
            </>
          ) : (
            <div className="mt-8 flex gap-x-2 md:gap-x-4">
              <Button
                variant="secondary-black"
                className="flex-1"
                disabled={isLoggingIn}
                type="button"
                onClick={() => setIsShowingEmailForm(false)}
              >
                Back
              </Button>

              <Button
                variant="primary-black"
                className="flex-1"
                type="submit"
                isLoading={isLoggingIn}
              >
                Connect
              </Button>
            </div>
          )}
        </>
      );
    }

    return (
      <ExtensionLogin
        countryCode={currentProxyLocation}
        account={account}
        onBack={() => setIsShowingEmailForm(false)}
        onLocationChange={(countryCode) => {
          setValue("proxyLocation", countryCode, {
            shouldValidate: true,
          });
        }}
      />
    );
  };

  const renderActionButton = () => {
    if (
      !isExtensionAvailable &&
      selectedLoginMethod === "extension" &&
      isChrome
    ) {
      return (
        <Button
          aria-label="Get Aimfox extension"
          rightIcon={<ArrowSquareOut />}
          asChild
        >
          <a
            target="_blank"
            href={`https://chromewebstore.google.com/detail/aimfox-linkedin-session-m/${chromeExtensionId}`}
            rel="noreferrer"
          >
            Get Aimfox extension
          </a>
        </Button>
      );
    }

    return (
      <Button type="button" onClick={handleNextClick}>
        Next
      </Button>
    );
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      {!isShowingEmailForm ? (
        <>
          <h2 className="mb-4 text-center text-headline-2xl-bold md:text-headline-xl-bold">
            {account ? "Re-connect" : "Connect"} your LinkedIn account
          </h2>

          <div className="flex flex-col gap-y-4">
            {loginMethods.map((option) => {
              const { title, subtitle, icon: OptionIcon, type } = option;
              const isOptionDisabled = type === "extension" && !isChrome;

              return (
                <LoginMethodOption
                  key={type}
                  title={title}
                  subtitle={subtitle}
                  icon={OptionIcon}
                  type={type}
                  isOptionDisabled={isOptionDisabled}
                  selectedLoginMethod={selectedLoginMethod}
                  setSelectedLoginMethod={() => {
                    if (selectedLoginMethod === type) return;
                    setSelectedLoginMethod(type);
                  }}
                />
              );
            })}

            {renderActionButton()}

            <div className="flex items-center justify-center gap-x-0.5">
              <Lock weight="fill" className="size-4 text-black-700" />

              <span className="text-caption-12-regular text-black-700">
                Secured with TLS 1.3 encryption
              </span>
            </div>
          </div>
        </>
      ) : (
        renderLoginForm()
      )}
    </form>
  );
}
