import React, { useEffect, useState } from "react";

import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import useAppStore from "common/hooks/useAppStore";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import AccountsTable from "./AccountsTable";
import AddAccountButton from "./AddAccountButton";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";
import ConnectAccountImage from "assets/images/empty-placeholders/connect-account.png";

export default function LinkedInAccounts() {
  const isDesktop = useTwBreakpoint("lg");
  const {
    license_count: licenseCount,
    licenses,
    accounts,
  } = useSelectedWorkspaceContext();
  const [searchText, setSearchText] = useState("");

  const lowercasedInput = searchText.trim().toLowerCase();
  const filteredAccounts = accounts.filter(({ full_name }) =>
    full_name.trim().toLowerCase().includes(lowercasedInput),
  );
  const assignedLicensesCount = licenses.filter(
    ({ account_id }) => !!account_id,
  ).length;

  const mainComponent =
    searchText.trim() && !filteredAccounts.length ? (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle="There were no results found for your search. Try searching for something else"
        actionComponent={
          <Button onClick={() => setSearchText("")}>Clear Search</Button>
        }
      />
    ) : (
      <AccountsTable accounts={filteredAccounts} />
    );

  useEffect(() => {
    if (!isDesktop) {
      useAppStore.getState().setHeaderContent({
        subtitle: `No. of seats: ${assignedLicensesCount}/${licenseCount}`,
      });
      return () => useAppStore.getState().setHeaderContent(null);
    }
  }, [isDesktop]);

  if (!accounts.length)
    return (
      <EmptyPlaceholder
        imageSrc={ConnectAccountImage}
        title="No LinkedIn accounts added"
        subtitle="Add a LinkedIn account to start using campaigns, leads and inbox. You can add unlimited accounts"
        actionComponent={<AddAccountButton />}
      />
    );

  return (
    <>
      <div className="flex flex-col justify-between lg:flex-row-reverse lg:items-center">
        <div className="flex gap-3 max-lg:mb-4 max-lg:flex-col lg:items-center">
          <span className="text-button-16 text-black-400 max-lg:hidden">
            No. of seats:{" "}
            <span className="text-black-950">
              {assignedLicensesCount}/{licenseCount}
            </span>
          </span>
          <AddAccountButton />
        </div>

        <SearchInput
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => setSearchText("")}
          className="lg:w-80"
          placeholder="Search accounts"
        />
      </div>

      {mainComponent}
    </>
  );
}
