import React, { useState } from "react";

import useMetrics from "common/datahooks/useMetrics";
import useSelectedMetric from "common/hooks/useSelectedMetric";
import useCampaigns from "common/datahooks/useCampaigns";
import TimeframeSelector from "common/components/TimeframeSelector";
import { formatDate } from "common/helpers/utils";
import MetricSelector from "common/components/MetricSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import RecentLeads from "common/components/RecentLeads";
import NoCampaignsPlaceholder from "common/components/NoCampaignsPlaceholder";

import WorkspaceAccountSelector from "../../../common/components/WorkspaceAccountSelector";
import { statsTimeframeOptions } from "../../campaigns/constants";

function Home() {
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const {
    selectedMetric,
    setSelectedMetric,
    selectedMetricParams,
    setSelectedMetricParams,
  } = useSelectedMetric();
  const {
    metrics,
    metricsSum,
    isLoadingMetrics,
    refetchMetrics,
    metricsError,
  } = useMetrics({
    parameters: selectedMetricParams,
    accountIds: selectedAccountIds,
  });
  const { campaigns, isLoadingCampaigns } = useCampaigns();

  const isLoading = isLoadingCampaigns || isLoadingMetrics;

  let noCampaignsPlaceholder = null;
  if (!isLoading && !campaigns?.length) {
    noCampaignsPlaceholder = <NoCampaignsPlaceholder />;
  }
  const selectedTimeframeOption = statsTimeframeOptions.find(
    ({ parameters }) => parameters === selectedMetricParams,
  );
  return (
    <>
      <div className="mb-4 flex flex-col items-start justify-between gap-4 lg:mb-10 lg:flex-row">
        <div className="flex flex-col gap-y-1 max-lg:hidden">
          <h2 className="text-headline-2xl-bold">Overview</h2>
          <p className="text-black-500">
            {selectedTimeframeOption
              ? selectedTimeframeOption.label
              : `${formatDate(selectedMetricParams.timestamps.from)} - ${formatDate(selectedMetricParams.timestamps.to)}`}{" "}
            statistics
          </p>
        </div>
        <div className="flex gap-x-2">
          <WorkspaceAccountSelector
            selectedAccountIds={selectedAccountIds}
            setSelectedAccountIds={setSelectedAccountIds}
          />
          <TimeframeSelector
            selectedParams={selectedMetricParams}
            setSelectedParams={setSelectedMetricParams}
          />
        </div>
      </div>
      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        isLoading={isLoadingMetrics}
      />

      {noCampaignsPlaceholder || (
        <div className="relative flex h-[420px] md:h-[480px]">
          <StatisticsGraph
            selectedMetric={selectedMetric}
            metrics={metrics}
            metricsSum={metricsSum}
            refetchMetrics={refetchMetrics}
            requestError={metricsError}
            isLoading={isLoading}
            isHourly={selectedMetricParams.bucketSize === "1 hour"}
          />
        </div>
      )}

      <RecentLeads showCampaignOrigin accountIds={selectedAccountIds} />
    </>
  );
}

export default Home;
