/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  CaretRight,
  Check,
  EnvelopeOpen,
  User,
  type Icon,
} from "@phosphor-icons/react";

import Skeleton from "common/components/ui/Skeleton";
import { CreatedTemplate } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { templateTypes } from "common/constants";
import { formatDate } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";
import DataTable from "common/components/DataTable";
import RenderIf from "common/components/RenderIf";

import TemplateActions from "./TemplateActions";
import TemplatePreview from "./TemplatePreview";
import TemplateDialog from "./TemplateDialog";

function MetricItem({
  icon: Icon,
  value,
  isLoading,
}: {
  icon: Icon;
  value: string | number;
  isLoading: boolean;
}) {
  return (
    <div className="flex items-center justify-center gap-1">
      {isLoading ? (
        <Skeleton className="h-5 w-12" />
      ) : (
        <>
          <Icon size={20} className="fill-black-400" />
          <span>{value}</span>
        </>
      )}
    </div>
  );
}

interface TemplatesTableProps {
  templates: CreatedTemplate[];
  isLoading: boolean;
}

type Action = "edit" | "delete";

export default function TemplatesTable({
  templates,
  isLoading,
}: TemplatesTableProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<Action>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(null);

  const selectedTemplate = templates.find(
    ({ id }) => id === selectedTemplateId,
  );

  function openActionDialog(modalAction: Action) {
    setAction(modalAction);
    setIsOpen(true);
  }

  function onRowClick(template: CreatedTemplate) {
    setSelectedTemplateId(template.id);

    if (isTabletOrDesktop) {
      setAction("edit");
    }

    setIsOpen(true);
  }

  const columns = useMemo(() => {
    const templateColumns: ColumnDef<CreatedTemplate>[] = [
      {
        accessorKey: "created_at",
        header: "Created at",
        cell: ({
          row: {
            original: { type, name, created_at },
          },
        }) => {
          const { icon: Icon } = templateTypes[type] ?? {};

          return (
            <div className="flex items-center gap-2">
              {isLoading ? (
                <Skeleton className="size-10" />
              ) : (
                <span className="inline-flex rounded-2xl border border-black-200 p-2.5">
                  <Icon size={20} />
                </span>
              )}

              <div>
                {isLoading ? (
                  <>
                    <Skeleton className="mb-0.5 h-4 w-32" />
                    <Skeleton className="h-6 w-20" />
                  </>
                ) : (
                  <>
                    <span className="text-left text-button-12 text-black-500">
                      Created on {formatDate(created_at)}
                    </span>
                    <h3 className="line-clamp-1 break-all text-left text-body-16-bold">
                      {name}
                    </h3>
                  </>
                )}
              </div>
            </div>
          );
        },
      },
    ];

    if (isTabletOrDesktop) {
      templateColumns.push(
        {
          accessorKey: "set",
          header: "Sent",
          cell: ({ row: { original } }) => (
            <MetricItem
              icon={User}
              value={original.stats?.sent}
              isLoading={isLoading}
            />
          ),
        },
        {
          accessorKey: "accepted_replied",
          header: "Accepted/Replied",
          cell: ({
            row: {
              original: { type, stats },
            },
          }) => {
            let value;
            if (!isLoading) {
              value = type === "NOTE_TEMPLATE" ? stats.accepted : stats.replies;
            }
            return (
              <MetricItem
                icon={EnvelopeOpen}
                value={value}
                isLoading={isLoading}
              />
            );
          },
        },
        {
          accessorKey: "success",
          header: "Success",
          cell: ({
            row: {
              original: { type, stats },
            },
          }) => {
            function getValue() {
              if (isLoading) return;

              const value =
                type === "NOTE_TEMPLATE" ? stats.accepted : stats.replies;

              if (!stats.sent) {
                return "0%";
              }

              return `${value ? Math.round((value / stats.sent) * 100) : 0}%`;
            }

            return (
              <MetricItem
                icon={Check}
                value={getValue()}
                isLoading={isLoading}
              />
            );
          },
        },
        {
          id: "actions",
          cell: ({ row: { original } }) => {
            if (isLoading)
              return <Skeleton className="inline-flex size-10 rounded-full" />;

            return (
              <TemplateActions
                openActionModal={(newAction) => {
                  setSelectedTemplateId(original.id);
                  openActionDialog(newAction);
                }}
              />
            );
          },
        },
      );
    } else {
      templateColumns.push({
        id: "actions",
        cell: () => (
          <Button intent="labelIcon" size="sm" variant="quaternary-black">
            <CaretRight />
          </Button>
        ),
      });
    }

    return templateColumns;
  }, [isLoading, isTabletOrDesktop]);

  return (
    <>
      <DataTable columns={columns} data={templates} onRowClick={onRowClick} />
      <RenderIf condition={!!selectedTemplate}>
        {isTabletOrDesktop ? (
          <TemplateDialog
            template={selectedTemplate}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            action={action}
          />
        ) : (
          <TemplatePreview
            template={selectedTemplate}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </RenderIf>
    </>
  );
}
