import React from "react";
import { Trash } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface RemoveImageButtonProps {
  removeLogo: () => void;
  isLoading: boolean;
  isDisabled: boolean;
}

export default function RemoveImageButton({
  removeLogo,
  isLoading,
  isDisabled,
}: RemoveImageButtonProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  return (
    <Button
      intent="iconOnly"
      variant={isTabletOrDesktop ? "tertiary-danger" : "quaternary-danger"}
      onClick={removeLogo}
      disabled={isDisabled}
      isLoading={isLoading}
    >
      <Trash />
    </Button>
  );
}
