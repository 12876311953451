import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { HTTPError, del, get, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Targeting } from "../types";

async function getTargetingRequest(
  workspaceId: string,
  campaignId: string,
): Promise<Targeting> {
  return (
    await get(`workspaces/${workspaceId}/campaigns/${campaignId}/targeting`)
  ).targeting;
}

async function setTargetingRequest(
  workspaceId: string,
  campaignId: string,
  targeting: Targeting,
): Promise<Targeting> {
  return (
    await post(`workspaces/${workspaceId}/campaigns/${campaignId}/targeting`, {
      ...targeting,
    })
  ).targeting;
}

async function deleteTargetingRequest(workspaceId: string, campaignId: string) {
  await del(`workspaces/${workspaceId}/campaigns/${campaignId}/targeting`);
}

export default function useTargeting() {
  const { id: campaignId } = useCampaignContext();

  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: targeting, isLoading: isLoadingTargeting } = useQuery({
    queryKey: campaignsKeys.targeting(campaignId),
    queryFn: () => getTargetingRequest(workspaceId, campaignId),
  });

  const { mutateAsync: setTargeting, isPending: isSettingTargeting } =
    useMutation({
      mutationFn: ({ campaignTargeting }: { campaignTargeting: Targeting }) =>
        setTargetingRequest(workspaceId, campaignId, campaignTargeting),
      onSuccess: (newTargeting) => {
        queryClient.setQueryData(
          campaignsKeys.targeting(campaignId),
          newTargeting,
        );
      },
    });

  const { mutateAsync: removeTargeting, isPending: isRemovingTargeting } =
    useMutation({
      mutationFn: () => deleteTargetingRequest(workspaceId, campaignId),
      onSuccess: () => {
        queryClient.setQueryData(campaignsKeys.targeting(campaignId), null);
        queryClient.setQueryData(campaignsKeys.audience(campaignId), []);
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  return {
    targeting,
    isLoadingTargeting,
    setTargeting,
    isSettingTargeting,
    removeTargeting,
    isRemovingTargeting,
  };
}
