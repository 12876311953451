import React, { useState } from "react";
import { Gear, Link as LinkIcon } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { Tag } from "common/components/ui/Tag";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { WorkspaceAccount } from "common/types";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import RestrictedComponent from "common/components/RestrictedComponent";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";

import LicenseTag from "./LicenseTag";
import AccountSettings from "./AccountSettings";
import { AccountAction } from "../types";
import AccountActions from "./AccountActions";

interface MobilePreviewProps {
  account: WorkspaceAccount;
  isOpen: boolean;
  action: AccountAction;
  setAction: (newAction: AccountAction) => void;
  onClose: () => void;
  openLicenseModal: () => void;
}

export default function MobilePreview({
  account,
  isOpen,
  action,
  setAction,
  onClose,
  openLicenseModal,
}: MobilePreviewProps) {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const isLicenseAvailable = useValidateAccountLicense(account.id);

  const {
    state,
    picture_url: pictureUrl,
    full_name: fullName,
    occupation,
    license,
  } = account;

  const isDisconnected = state !== "LoggedIn";

  function handleLicense() {
    if (isLicenseAvailable) {
      setAction("license");
    } else {
      onClose();
      openLicenseModal();
    }
  }

  return (
    <>
      <Drawer
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        onAfterClose={() => {
          setAction(null);
        }}
      >
        <DrawerContent>
          {action ? (
            <AccountActions
              account={account}
              action={action}
              goBack={() => setAction(null)}
              onSuccess={onClose}
            />
          ) : (
            <>
              <div className="flex gap-x-2 py-4">
                <ProfileImage size="lg" src={pictureUrl} />

                <div className="flex w-full flex-row items-center justify-between gap-2">
                  <div className="flex flex-col">
                    <span className="text-headline-xl-bold">{fullName}</span>

                    <span className="line-clamp-1 text-body-14-regular text-black-600">
                      {occupation}
                    </span>
                  </div>

                  <Button
                    size="lg"
                    intent="iconOnly"
                    variant="secondary-black"
                    onClick={() => setIsSettingsOpen(true)}
                  >
                    <Gear size={24} weight="fill" />
                  </Button>
                </div>
              </div>

              <div className="mb-8 flex border-t border-t-black-200 py-4">
                <div className="flex flex-1 flex-col items-start gap-y-1">
                  <span className="text-body-14-regular text-black-600">
                    Seat
                  </span>

                  <LicenseTag license={license} />
                </div>

                <div className="flex flex-1 flex-col items-start gap-y-1">
                  <span className="text-body-14-regular text-black-600">
                    Status
                  </span>

                  <Tag variant={isDisconnected ? "disabled" : "success"}>
                    {isDisconnected ? "Not connected" : "Connected"}
                  </Tag>
                </div>
              </div>

              <div className="flex flex-col gap-y-2">
                <Button
                  size="lg"
                  variant={license ? "tertiary-black" : "primary-black"}
                  onClick={handleLicense}
                >
                  {license ? "Unassign" : "Assign"} seat
                </Button>

                <RenderIf condition={isDisconnected}>
                  <div className="flex items-center gap-x-2.5">
                    <Button
                      size="lg"
                      variant="secondary-black"
                      className="flex-1"
                      onClick={() => setAction("reconnect")}
                    >
                      Reconnect account
                    </Button>

                    <Button
                      size="lg"
                      variant="secondary-black"
                      intent="iconOnly"
                      onClick={() => setAction("reconnectLink")}
                    >
                      <LinkIcon />
                    </Button>
                  </div>
                </RenderIf>

                <RenderIf condition={license && !!license.expires_at}>
                  <Button
                    size="lg"
                    variant="secondary-black"
                    onClick={() => setAction("reactivate")}
                  >
                    Remove cancellation
                  </Button>
                </RenderIf>

                <RestrictedComponent
                  disabledForRoles={[]}
                  hiddenForRoles={["member"]}
                >
                  <RenderIf condition={!license}>
                    <Button
                      size="lg"
                      variant="tertiary-danger"
                      onClick={() => setAction("delete")}
                    >
                      Delete account
                    </Button>
                  </RenderIf>
                </RestrictedComponent>
              </div>
            </>
          )}
        </DrawerContent>
      </Drawer>

      <AccountSettings
        account={account}
        isOpen={isSettingsOpen}
        setIsOpen={setIsSettingsOpen}
      />
    </>
  );
}
