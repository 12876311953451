import React from "react";
import {
  Envelope,
  type Icon,
  Info,
  Lock,
  UserCheck,
  UserCircleDashed,
  UserMinus,
  UsersThree,
} from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import RenderIf from "common/components/RenderIf";

import { AudienceStats } from "../../../types";

interface DetailItemProps {
  icon: Icon;
  label: string;
  value: number;
  infoText: string;
}

export default function AudienceDetails({
  audienceStats,
}: {
  audienceStats: AudienceStats;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;

  const {
    valid,
    inmailOptimization,
    locked,
    blocked,
    headless,
    alreadyConnected,
  } = audienceStats ?? {};

  const totalUsersFound = audienceStats
    ? valid + locked + blocked + headless + alreadyConnected
    : 0;

  const detailItems: DetailItemProps[] = [
    {
      icon: UserCheck,
      label: "Valid targets",
      value: valid,
      infoText:
        "The number of targets that will receive communication from this campaign",
    },
    {
      icon: Envelope,
      label: "InMail recipients",
      value: inmailOptimization,
      infoText:
        "The number of Valid targets that can receive a free InMail. These are Premium LinkedIn accounts with an open profile",
    },
    {
      icon: UsersThree,
      label: "Already connected",
      value: alreadyConnected,
      infoText:
        "You are already connected with these targets. 1st connections cannot be enrolled in a campaign",
    },
    {
      icon: Lock,
      label: "Locked-already in campaign",
      value: locked,
      infoText:
        "These targets are already enrolled in a different Aimfox campaign and cannot be engaged with. A LinkedIn user can only be added to one Aimfox campaign in order to avoid sending conflicting communication, or spam",
    },
    {
      icon: UserMinus,
      label: "Blacklisted",
      value: blocked,
      infoText:
        "These accounts have been blacklisted, and will never be added to your campaigns",
    },
    {
      icon: UserCircleDashed,
      label: "Out of network",
      value: headless,
      infoText:
        "These LinkedIn accounts are out of your network and you cannot engage with them",
    },
  ];

  return (
    <Component>
      <Trigger asChild>
        <Button
          className="max-md:flex-1"
          size={isTabletOrDesktop ? "lg" : "md"}
          variant="tertiary-black"
          leftIcon={<Info weight="fill" />}
        >
          View details
        </Button>
      </Trigger>
      <Content>
        <h5 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Audience details
        </h5>
        <p className="mb-8 text-center text-black-500">
          You will not engage with locked, blacklisted, and out of network
          accounts
        </p>
        {detailItems.map(({ icon: Icon, infoText, label, value }) => (
          <article
            key={label}
            className="flex w-full items-center border-b border-b-black-200 py-3"
          >
            <Button intent="labelIcon" variant="secondary-black" size="sm">
              <Icon />
            </Button>
            <span className="ml-3 mr-1 text-button-16">{label}</span>
            <Tooltip>
              <TooltipTrigger>
                <Info weight="fill" size={20} className="fill-black-300" />
              </TooltipTrigger>
              <TooltipContent>{infoText}</TooltipContent>
            </Tooltip>
            <span className="ml-auto">{value}</span>
          </article>
        ))}
        <div className="mt-3 flex justify-end gap-1">
          <p>Total users found: {totalUsersFound}</p>
          <Tooltip>
            <TooltipTrigger>
              <Info weight="fill" size={20} className="fill-black-300" />
            </TooltipTrigger>
            <TooltipContent>
              The number of targets found for this campaign. Sometimes it is
              possible for LinkedIn not to return the exact number of targets
              you requested when setting up your search. A 5-10% drop is normal.
            </TooltipContent>
          </Tooltip>
        </div>
        <RenderIf condition={isTabletOrDesktop}>
          <DialogClose asChild>
            <Button
              size="md"
              className="mt-8 self-end"
              variant="secondary-black"
            >
              Close
            </Button>
          </DialogClose>
        </RenderIf>
      </Content>
    </Component>
  );
}
