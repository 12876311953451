import { useQuery } from "@tanstack/react-query";
import { produce } from "immer";

import { get } from "../helpers/HTTP";
import { UserRole, Workspace } from "../types";

async function getWorkspaces() {
  const { workspaces } = await get(`workspaces`);

  const sortedWorkspaces = (workspaces as Workspace[]).sort((a, b) => {
    const rolePriority: Record<UserRole, number> = {
      owner: 1,
      admin: 2,
      member: 3,
    };

    return rolePriority[a.role] - rolePriority[b.role];
  });

  return produce(sortedWorkspaces, (draftWorkspaces) => {
    for (let i = 0; i < draftWorkspaces.length; i += 1) {
      const workspace = draftWorkspaces[i];
      for (let j = 0; j < workspace.accounts.length; j += 1) {
        const account = workspace.accounts[j];
        account.license = workspace.licenses.find(
          ({ account_id }) => account_id === account.id,
        );
      }
    }
  });
}

export default function useWorkspaces() {
  const { data: workspaces, refetch: refetchWorkspaces } = useQuery({
    queryKey: ["workspaces"],
    queryFn: getWorkspaces,
    refetchOnMount: false,
  });

  return {
    workspaces,
    refetchWorkspaces,
  };
}
