import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useWhiteLabelMutations from "../../../../datahooks/useWhiteLabelMutations";
import UploadButton from "./UploadButton";
import RemoveImageButton from "./RemoveImageButton";

export default function ActionButtons({
  isLogoAvailable,
}: {
  isLogoAvailable: boolean;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { updateWhiteLabel, isUpdatingWhiteLabel, removeLogo, isRemovingLogo } =
    useWhiteLabelMutations();

  const uploadButton = (
    <UploadButton
      uploadLogo={(file) => updateWhiteLabel({ logo: file })}
      isDisabled={isRemovingLogo}
      isLoading={isUpdatingWhiteLabel}
    />
  );

  const removeButton = (
    <RemoveImageButton
      removeLogo={() => removeLogo()}
      isDisabled={isUpdatingWhiteLabel}
      isLoading={isRemovingLogo}
    />
  );

  if (!isTabletOrDesktop) {
    return isLogoAvailable ? removeButton : uploadButton;
  }

  return (
    <div className="flex items-center gap-x-2">
      {uploadButton}
      {isLogoAvailable && removeButton}
    </div>
  );
}
