import React from "react";

import ProfileImage from "../ui/ProfileImage";

interface LinkedinInfoProps {
  pictureUrl: string;
  fullName: string;
  occupation: string;
}

export default function LinkedinInfo({
  pictureUrl,
  fullName,
  occupation,
}: LinkedinInfoProps) {
  return (
    <div className="flex w-full items-center gap-3 rounded-20 border border-black-200 p-4">
      <ProfileImage
        className="rounded-full"
        src={pictureUrl}
        alt={`${fullName} profile`}
      />

      <div className="flex flex-col">
        <span className="text-button-14">{fullName}</span>

        <span className="text-caption-12-regular text-black-500">
          {occupation}
        </span>
      </div>
    </div>
  );
}
