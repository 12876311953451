import React, { useRef } from "react";
import { Upload } from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

interface UploadButtonProps {
  uploadLogo: (file: File) => void;
  isLoading: boolean;
  isDisabled: boolean;
}

export default function UploadButton({
  uploadLogo,
  isLoading,
  isDisabled,
}: UploadButtonProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleClick() {
    fileInputRef.current?.click();
  }

  return (
    <>
      <Button
        variant={isTabletOrDesktop ? "secondary-black" : "quaternary-black"}
        className="ml-auto"
        intent={isTabletOrDesktop ? "default" : "iconOnly"}
        aria-label="upload-image-button"
        onClick={handleClick}
        isLoading={isLoading}
        disabled={isDisabled}
      >
        {isTabletOrDesktop ? "Upload image" : <Upload />}
      </Button>
      <input
        ref={fileInputRef}
        id="image-upload"
        type="file"
        className="hidden"
        accept="image/*"
        onChange={(e) => uploadLogo(e.currentTarget.files[0])}
      />
    </>
  );
}
