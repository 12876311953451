import clsx from "clsx";
import React from "react";
import { Icon } from "@phosphor-icons/react";

import RenderIf from "../RenderIf";
import { Tag } from "../ui/Tag";
import RadioButton from "../ui/RadioButton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/Tooltip";

interface LoginMethodOptionProps {
  title: string;
  subtitle: string;
  icon: Icon;
  type: string;
  isOptionDisabled: boolean;
  selectedLoginMethod: string;
  setSelectedLoginMethod: () => void;
}

export default function LoginMethodOption({
  title,
  subtitle,
  icon: OptionIcon,
  type,
  isOptionDisabled,
  selectedLoginMethod,
  setSelectedLoginMethod,
}: LoginMethodOptionProps) {
  const renderButton = () => (
    <button
      type="button"
      aria-label={title}
      className={clsx([
        "flex cursor-pointer items-center justify-between gap-4 rounded-2xl border p-4",
        selectedLoginMethod === type
          ? "border-purple-600 bg-purple-50"
          : "border-black-200",
      ])}
      disabled={isOptionDisabled}
      onClick={setSelectedLoginMethod}
    >
      <div className="flex flex-row items-center gap-2">
        <OptionIcon
          size={32}
          className={clsx(isOptionDisabled && "text-black-400")}
        />

        <div className="flex flex-col text-start">
          <div className="flex flex-row items-center gap-x-2">
            <p
              className={clsx(
                "text-button-14",
                isOptionDisabled && "text-black-400",
              )}
            >
              {title}
            </p>

            <RenderIf condition={type === "extension"}>
              <Tag
                variant={isOptionDisabled ? "primary-black" : "primary"}
                className={clsx(isOptionDisabled && "bg-black-400")}
              >
                Recommended
              </Tag>
            </RenderIf>
          </div>

          <span className="text-caption-12-regular text-black-500">
            {subtitle}
          </span>
        </div>
      </div>

      <RadioButton
        id={type}
        className={clsx(
          "mt-1",
          selectedLoginMethod === type && "border-purple-500",
        )}
        checked={selectedLoginMethod === type}
        isStyleOnly
      />
    </button>
  );

  if (isOptionDisabled) {
    return (
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger asChild>{renderButton()}</TooltipTrigger>

          <TooltipContent className="w-60">
            <p>
              Your browser does not support the Aimfox extension. Please
              download Google Chrome to use this feature
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return renderButton();
}
