import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { del, get, patch, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Member, UserRole, Workspace } from "common/types";
import getQueryKeys from "common/datahooks/getQueryKeys";

async function fetchMembers(workspaceId: string) {
  return (await get(`workspaces/${workspaceId}/members`)).members;
}

async function inviteMemberRequest(
  subscriptionId: string,
  email: string,
  role: string,
): Promise<Member> {
  return (
    await post(`workspaces/${subscriptionId}/members`, {
      email: email.toLocaleLowerCase(),
      role,
    })
  ).invite;
}

async function changeMemberRoleRequest(
  workspaceId: string,
  memberId: string,
  role: UserRole,
) {
  return patch(`workspaces/${workspaceId}/members/${memberId}`, {
    role,
  });
}

async function removeMemberRequest(workspaceId: string, memberId: string) {
  await del(`workspaces/${workspaceId}/members/${memberId}`);
}

async function revokeInviteRequest(workspaceId: string, inviteToken: string) {
  return del(`workspaces/${workspaceId}/invites/${inviteToken}`);
}

async function resendInviteRequest(workspaceId: string, inviteToken: string) {
  return post(`workspaces/${workspaceId}/invites/${inviteToken}`);
}

export default function useWorkspaceMembers({
  disableQuery,
}: {
  disableQuery?: boolean;
} = {}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { membersKeys } = getQueryKeys(workspaceId);

  const {
    data: members,
    isLoading: isLoadingMembers,
    isError: isMembersError,
    refetch: refetchMembers,
  } = useQuery<Member[]>({
    queryKey: membersKeys.list,
    queryFn: () => fetchMembers(workspaceId),
    enabled: !disableQuery,
  });

  const {
    mutateAsync: inviteMember,
    isPending: isInvitingMember,
    error: inviteMemberError,
    reset: resetInviteMember,
  } = useMutation({
    mutationFn: ({ email, role }: { email: string; role: string }) =>
      inviteMemberRequest(workspaceId, email, role),
    onSuccess: (member) => {
      queryClient.setQueryData(membersKeys.list, (prevMembers: Member[]) => [
        ...prevMembers,
        member,
      ]);
    },
  });

  const { mutateAsync: changeMemberRole, isPending: isChangingRole } =
    useMutation({
      mutationFn: ({ memberId, role }: { memberId: string; role: UserRole }) =>
        changeMemberRoleRequest(workspaceId, memberId, role),
      onSuccess: (_, { memberId, role }) => {
        queryClient.setQueryData(
          membersKeys.list,
          (prevMembers: Member[] | undefined) =>
            prevMembers?.map((member) =>
              member.customer_id === memberId ? { ...member, role } : member,
            ) ?? [],
        );
        toast.success("Role updated");
      },
    });

  const { mutateAsync: removeMember, isPending: isRemovingMember } =
    useMutation({
      mutationFn: ({ memberId }: { memberId: string }) =>
        removeMemberRequest(workspaceId, memberId),
      onSuccess: (_, { memberId }) => {
        queryClient.setQueryData(membersKeys.list, (prevMembers: Member[]) =>
          prevMembers.filter(({ customer_id }) => customer_id !== memberId),
        );
        toast.success("Member removed");
      },
    });

  const { mutateAsync: leaveWorkspace, isPending: isLeavingWorkspace } =
    useMutation({
      mutationFn: ({ memberId }: { memberId: string }) =>
        removeMemberRequest(workspaceId, memberId),
      onSuccess: (_, { memberId }) => {
        queryClient.setQueryData(membersKeys.list, (prevMembers: Member[]) =>
          prevMembers.filter(({ customer_id }) => customer_id !== memberId),
        );
        navigate("/workspaces");
        queryClient.setQueryData<Workspace[]>(
          ["workspaces"],
          (prevWorkspaces) =>
            prevWorkspaces.filter((workspace) => workspace.id !== workspaceId),
        );
        toast.success("You've left workspace");
      },
    });

  const { mutateAsync: revokeInvite, isPending: isRevokingInvite } =
    useMutation({
      mutationFn: ({ inviteToken }: { inviteToken: string }) =>
        revokeInviteRequest(workspaceId, inviteToken),
      onSuccess: (_, { inviteToken }) => {
        queryClient.setQueryData(
          membersKeys.list,
          (prevMembers: Member[] | undefined) =>
            prevMembers?.filter((member) => member.token !== inviteToken) ?? [],
        );
        toast.success("Invite revoked");
      },
    });

  const { mutateAsync: resendInvite, isPending: isResendingInvite } =
    useMutation({
      mutationFn: ({ inviteToken }: { inviteToken: string }) =>
        resendInviteRequest(workspaceId, inviteToken),
      onSuccess: () => {
        toast.success("Invite resent");
      },
    });

  return {
    members,
    isLoadingMembers,
    isMembersError,
    refetchMembers,
    inviteMember,
    isInvitingMember,
    inviteMemberError,
    resetInviteMember,
    removeMember,
    isRemovingMember,
    leaveWorkspace,
    isLeavingWorkspace,
    changeMemberRole,
    isChangingRole,
    revokeInvite,
    isRevokingInvite,
    resendInvite,
    isResendingInvite,
  };
}
