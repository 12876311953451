import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import useUnreadToken from "common/datahooks/useUnreadToken";

const wsBaseUrl = import.meta.env.VITE_INBOX_WS_URL;

// Create singleton instances to track the global connection
let globalSocket: WebSocket | null = null;
let globalSocketPromise: Promise<void> | null = null;
const subscribers = new Set<(count: number) => void>();

// Last known count of unread messages
let lastKnownCount = 0;

export default function useUnreadMessages() {
  const { unreadToken } = useUnreadToken();
  const [unreadMessagesCount, setUnreadMessagesCount] =
    useState(lastKnownCount);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  async function connectUnreadMessages(): Promise<void> {
    // If we already have a connection, just subscribe and update with last known count
    if (globalSocket?.readyState === WebSocket.OPEN) {
      subscribers.add(setUnreadMessagesCount);
      setUnreadMessagesCount(lastKnownCount);
      return;
    }

    // If we're already connecting, wait for that connection
    if (globalSocketPromise) {
      await globalSocketPromise;
      subscribers.add(setUnreadMessagesCount);
      setUnreadMessagesCount(lastKnownCount);
      return;
    }

    // Create a new connection
    globalSocketPromise = new Promise((resolve, reject) => {
      const websocketUrl = `${wsBaseUrl}/unread?authorization=${unreadToken}`;
      const websocket = new WebSocket(websocketUrl);

      websocket.onmessage = (event) => {
        const { unread_count: unreadCount, refetch } = JSON.parse(event.data);
        lastKnownCount = unreadCount;
        subscribers.forEach((callback) => callback(unreadCount));

        // Only set shouldRefetch if the server requests it
        if (refetch) {
          setShouldRefetch(true);
          // Reset the refetch flag after a delay
          setTimeout(() => {
            setShouldRefetch(false);
          }, 2500);
        }
      };

      websocket.onopen = () => {
        globalSocket = websocket;
        subscribers.add(setUnreadMessagesCount);
        resolve();
      };

      websocket.onerror = () => {
        globalSocketPromise = null;
        reject();
      };

      websocket.onclose = () => {
        globalSocket = null;
        globalSocketPromise = null;
      };
    });

    try {
      await globalSocketPromise;
    } catch (error) {
      // Handle connection error
      toast.error("Loading unread messages failed, please refresh the page");

      // Log the error to Sentry
      Sentry.captureMessage("WebSocket connection failed for unread messages");
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (unreadToken) {
      connectUnreadMessages();
    }

    return () => {
      subscribers.delete(setUnreadMessagesCount);

      // Only close the socket if there are no more subscribers
      if (subscribers.size === 0 && globalSocket) {
        globalSocket.close();
        globalSocket = null;
        globalSocketPromise = null;
      }
    };
  }, [unreadToken]);

  return { unreadMessagesCount, shouldRefetch };
}
