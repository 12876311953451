import { useState } from "react";

import { Campaign, CampaignState } from "common/types";

export default function useCampaignsTable(campaigns: Campaign[]) {
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [campaignStates, setCampaignStates] = useState<CampaignState[]>([]);
  const [searchText, setSearchText] = useState("");

  const filteredCampaigns = campaigns
    ? campaigns.filter(({ name, state, owner }) => {
        const isMatchingName = searchText
          ? name.trim().toLowerCase().includes(searchText.trim().toLowerCase())
          : true;
        const isMatchingState = campaignStates.length
          ? campaignStates.includes(state)
          : true;
        const isMatchingOwner = selectedAccountIds.length
          ? selectedAccountIds.includes(owner)
          : true;

        return isMatchingName && isMatchingState && isMatchingOwner;
      })
    : [];

  function clearFilters() {
    setSearchText("");
    setCampaignStates([]);
    setSelectedAccountIds([]);
  }

  return {
    searchText,
    setSearchText,
    campaignStates,
    setCampaignStates,
    selectedAccountIds,
    setSelectedAccountIds,
    filteredCampaigns,
    hasFilters:
      !!searchText || !!campaignStates.length || !!selectedAccountIds.length,
    clearFilters,
  };
}
