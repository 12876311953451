import React, { useState } from "react";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useNavStore from "common/hooks/useNavStore";
import RenderIf from "common/components/RenderIf";

import ResetTargeting from "../ResetTargeting";

interface StepperProps {
  selectedIndex: number;
  goToNextStep: () => void;
  isNextStepDisabled?: boolean;
  isNextButtonLoading?: boolean;
  isMiningTargets?: boolean;
}

const STEPS = [
  { label: "Targeting", path: "targeting" },
  { label: "Audience", path: "audience" },
  { label: "Campaign Flow", path: "flows" },
  { label: "Schedule", path: "schedule" },
  { label: "Preview", path: "review" },
];

export default function Stepper({
  selectedIndex,
  goToNextStep,
  isNextStepDisabled = false,
  isNextButtonLoading = false,
  isMiningTargets = false,
}: StepperProps) {
  const isDesktop = useTwBreakpoint("lg");
  const isNavExpanded = useNavStore((state) => state.isNavExpanded);
  const [isResetTargetingOpen, setIsResetTargetingOpen] = useState(false);

  const progress = `${(selectedIndex / (STEPS.length - 1)) * 100}%`;

  return (
    <>
      <div
        className={clsx(
          "fixed inset-x-0 bottom-0 z-bottomNav flex flex-col items-center justify-between gap-4 border-t border-t-black-200 bg-white p-4 transition-[margin-left] duration-500 lg:flex-row lg:py-5",
          isNavExpanded ? "lg:ml-[266px]" : "lg:ml-[72px]",
        )}
      >
        <RenderIf condition={!isDesktop}>
          <div className="relative flex w-full flex-1">
            {/* Progress */}
            <div className="absolute top-0.5 h-1 w-full overflow-hidden rounded-full bg-black-200">
              <div
                className="h-full rotate-180 bg-purple-400"
                style={{ width: progress }}
              />
            </div>

            {/* Steps */}
            <div className="relative flex w-full justify-between">
              {STEPS.map(({ path }, index) => (
                <div
                  className={clsx(
                    "relative z-10 flex size-2 rounded-full",
                    index > selectedIndex && "bg-black-200",
                    index === selectedIndex && "bg-purple-400",
                  )}
                  key={path}
                />
              ))}
            </div>
          </div>
        </RenderIf>

        <div className="mx-auto flex w-full items-center justify-between lg:max-w-[1920px] lg:px-2 xl:px-16">
          <Button
            variant="quaternary-black"
            size="lg"
            leftIcon={<ArrowLeft />}
            className={selectedIndex <= 1 && "invisible"}
            asChild
          >
            <Link
              to={`../${STEPS[selectedIndex >= 1 ? selectedIndex - 1 : 0]}`}
            >
              Back
            </Link>
          </Button>

          {isDesktop ? (
            <div className="flex items-center">
              {STEPS.map(({ label, path }, index) => {
                if (selectedIndex === index) {
                  return (
                    <div
                      key={path}
                      className="flex h-8 items-center text-nowrap rounded-full border-x border-x-transparent bg-purple-500 px-4 text-button-12 capitalize text-white"
                    >
                      {label}
                    </div>
                  );
                }
                if (selectedIndex > index) {
                  const isTargetingStep = index === 0;
                  return (
                    <>
                      <Button
                        key={path}
                        variant="tertiary-black"
                        size="sm"
                        className="capitalize"
                        asChild={!isTargetingStep}
                        // special logic for editing targeting
                        onClick={
                          isTargetingStep
                            ? () => setIsResetTargetingOpen(true)
                            : undefined
                        }
                        // editing targeting is disabled while mining targets
                        disabled={isMiningTargets}
                      >
                        {isTargetingStep ? (
                          label
                        ) : (
                          <Link to={`../${path}`}>{label}</Link>
                        )}
                      </Button>
                      <div className="h-px w-1 bg-purple-100 xl:w-6" />
                    </>
                  );
                }
                return (
                  <span
                    key={path}
                    className="ml-1 border-x border-x-transparent px-4 text-button-12 capitalize text-black-400 xl:ml-6"
                  >
                    {label}
                  </span>
                );
              })}
            </div>
          ) : (
            <span className="flex-1 text-center text-button-14 capitalize">
              {STEPS[selectedIndex].label}
            </span>
          )}

          <Button
            onClick={goToNextStep}
            disabled={isNextStepDisabled}
            isLoading={isNextButtonLoading}
            size="lg"
            rightIcon={<ArrowRight />}
          >
            Next
          </Button>
        </div>
      </div>
      <ResetTargeting
        isOpen={isResetTargetingOpen}
        setIsOpen={setIsResetTargetingOpen}
      />
    </>
  );
}
