import React, { useState } from "react";
import { FunnelSimple } from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import RenderIf from "common/components/RenderIf";

import CampaignSelector from "./CampaignSelector/CampaignSelector";
import LabelSelector from "./LabelSelector/LabelSelector";

interface SelectedFilters {
  accountIds: string[];
  campaignIds: string[];
  labelIds: string[];
}

interface FilterConversationsProps {
  selectedAccountIds: string[];
  selectedCampaignIds: string[];
  selectedLabelIds: string[];
  onApplyFilters: (filters: {
    accountIds: string[];
    campaignIds: string[];
    labelIds: string[];
  }) => void;
}

export default function FilterConversations({
  selectedAccountIds,
  selectedCampaignIds,
  selectedLabelIds,
  onApplyFilters,
}: FilterConversationsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    accountIds: selectedAccountIds,
    campaignIds: selectedCampaignIds,
    labelIds: selectedLabelIds,
  });

  function applyFilters(): void {
    const { accountIds, campaignIds, labelIds } = selectedFilters;

    onApplyFilters({
      accountIds,
      campaignIds,
      labelIds,
    });

    setIsDialogOpen(false);
  }

  function clearFilters(): void {
    setSelectedFilters({
      accountIds: [],
      campaignIds: [],
      labelIds: [],
    });
  }

  function handleDialogChange(isOpen: boolean): void {
    setIsDialogOpen(isOpen);

    if (isOpen) {
      setSelectedFilters({
        accountIds: selectedAccountIds,
        campaignIds: selectedCampaignIds,
        labelIds: selectedLabelIds,
      });
    }
  }

  const hasActiveFilters =
    selectedFilters.accountIds.length > 0 ||
    selectedFilters.campaignIds.length > 0 ||
    selectedFilters.labelIds?.length > 0;

  const isClearButtonDisabled =
    !selectedFilters.accountIds.length &&
    !selectedFilters.campaignIds.length &&
    !selectedFilters.labelIds?.length;

  const updateSelectedFilter =
    (key: keyof SelectedFilters) => (value: string[]) => {
      setSelectedFilters((prev) => ({ ...prev, [key]: value }));
    };

  return (
    <Component open={isDialogOpen} onOpenChange={handleDialogChange}>
      <Trigger asChild>
        <Button
          intent="iconOnly"
          variant="secondary-black"
          size="lg"
          className="group relative"
        >
          <FunnelSimple />

          <RenderIf condition={hasActiveFilters}>
            <div className="absolute right-3.5 top-4 size-1.5 rounded-full bg-purple-500 outline outline-black-100 group-hover:outline-black-200" />
          </RenderIf>
        </Button>
      </Trigger>

      <Content>
        <h2 className="pb-6 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Inbox filters
        </h2>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <span className="text-button-16">Filter by account</span>

            <WorkspaceAccountSelector
              selectedAccountIds={selectedFilters.accountIds}
              setSelectedAccountIds={updateSelectedFilter("accountIds")}
              showAllSelectedAccount
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-button-16">Filter by campaign</span>

            <CampaignSelector
              selectedCampaignIds={selectedFilters.campaignIds}
              setSelectedCampaignIds={updateSelectedFilter("campaignIds")}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-button-16">Filter by labels</span>

            <LabelSelector
              selectedLabelIds={selectedFilters.labelIds}
              setSelectedLabelIds={updateSelectedFilter("labelIds")}
            />
          </div>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-4">
          <Button
            variant="tertiary-black"
            size={isTabletOrDesktop ? "md" : "lg"}
            onClick={clearFilters}
            disabled={isClearButtonDisabled}
          >
            Clear all filters
          </Button>

          <Button size={isTabletOrDesktop ? "md" : "lg"} onClick={applyFilters}>
            Apply filters
          </Button>
        </div>
      </Content>
    </Component>
  );
}
