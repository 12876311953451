import React from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { Origin } from "common/types";

export default function OriginsInfo({ origins }: { origins: Origin[] }) {
  if (origins.length > 1) {
    return (
      <>
        | Origin:{" "}
        <Tooltip>
          <TooltipTrigger className="text-caption-12-bold">
            Multiple sources
          </TooltipTrigger>

          <TooltipContent className="w-full max-w-64">
            {origins.map((origin) => origin.name).join(", ")}
          </TooltipContent>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      | Origin:{" "}
      <span className="max-w-[50ch] truncate text-caption-12-bold">
        {origins[0]?.name}
      </span>
    </>
  );
}
