import React from "react";

import ActionButtons from "./ActionButtons";

export default function WhiteLabelLogo({ logo }: { logo: string }) {
  return (
    <div className="flex items-center gap-x-3 border-b border-black-200 max-md:py-4 md:h-16 md:px-3">
      <div className="flex flex-1 flex-col max-md:gap-2 md:flex-row">
        <h4 className="text-black-600 md:w-full md:max-w-[224px]">Logo</h4>
        {logo ? (
          <a
            href={logo}
            target="_blank"
            rel="noreferrer"
            className="text-button-14 text-blue-500 underline transition-colors hover:text-blue-600"
          >
            Preview logo
          </a>
        ) : (
          <span className="text-button-14 text-black-400">
            No file uploaded
          </span>
        )}
      </div>

      <ActionButtons isLogoAvailable={!!logo} />
    </div>
  );
}
