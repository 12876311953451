import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useGTM from "common/hooks/useGTM";
import { LoginSchema, LoginSchemaType } from "common/schemas";
import { WorkspaceAccount } from "common/types";

import Login from "./Login";
import ThisWasMeChallenge from "./ThisWasMeChallenge";
import EnterPin from "./EnterPin";
import RenderIf from "../RenderIf";

export interface LinkedInLoginProps {
  onSuccess: () => void;
  account?: WorkspaceAccount;
}

type CurrentStep = "login" | "pin" | "thiswasme";

export default function LinkedInLogin({
  onSuccess,
  account = null,
}: LinkedInLoginProps) {
  const { sendEvent } = useGTM();
  const [currentStep, setCurrentStep] = useState<CurrentStep>("login");

  const formReturn = useForm<LoginSchemaType>({
    defaultValues: {
      email: account?.email ?? "",
      password: "",
      proxyLocation: "",
    },
    resolver: valibotResolver(LoginSchema),
  });

  function onAppChallengeFail(): void {
    setCurrentStep("login");
    formReturn.setError("password", {
      message: "Error resolving app challenge",
    });
  }

  function onLoginSuccess(): void {
    sendEvent("connect_linkedin_account_success");
    onSuccess();
  }

  const steps = {
    login: {
      title: "Connect your LinkedIn account",
      mainComponent: (
        <Login
          account={account}
          onSuccess={onLoginSuccess}
          formReturn={formReturn}
          setStep={setCurrentStep}
        />
      ),
    },
    pin: {
      title: "Enter your PIN",
      subtitle:
        "You received a pin from LinkedIn via SMS, email or authenticator app. Please enter it below to connect your LinkedIn account",
      mainComponent: (
        <EnterPin
          email={formReturn.getValues("email")}
          onSuccess={onLoginSuccess}
        />
      ),
    },
    thiswasme: {
      title: "Confirm your login",
      subtitle:
        "LinkedIn wants to make sure this is an authorized login to your account. Please go to your LinkedIn app and confirm this connection",
      mainComponent: (
        <ThisWasMeChallenge
          email={formReturn.getValues("email")}
          onSuccess={onLoginSuccess}
          onAppChallengeFail={onAppChallengeFail}
        />
      ),
    },
  };

  const { title, mainComponent } = steps[currentStep];

  return (
    <>
      <RenderIf condition={currentStep !== "login"}>
        <h2 className="mb-4 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          {title}
        </h2>
      </RenderIf>

      {mainComponent}
    </>
  );
}
