import React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { CampaignProvider } from "common/helpers/CampaignContext";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import Loader from "common/components/Loader";

import CampaignDetails from "./CampaignDetails";
import useCampaignDetails from "../datahooks/useCampaignDetails";
import CreateCampaign from "./CreateCampaign";
import { CampaignInfo, CampaignInfoLoader } from "./CampaignInfo";

export default function Campaign() {
  const { campaignId } = useParams();
  const { campaign, isLoadingCampaign, campaignError, refetchCampaign } =
    useCampaignDetails(campaignId);

  if (campaignError) {
    return (
      <ErrorPlaceholder
        errorMessage="Error Loading Campaign"
        onRetry={refetchCampaign}
      />
    );
  }

  if (isLoadingCampaign) {
    return (
      <>
        <CampaignInfoLoader />
        <Loader />
      </>
    );
  }

  return (
    <CampaignProvider value={campaign}>
      <CampaignInfo />
      <Routes>
        <Route path="details" element={<CampaignDetails />} />
        <Route path="create/*" element={<CreateCampaign />} />
        {campaign.state === "INIT" ? (
          <Route
            path="/"
            element={<Navigate to="create/targeting" replace />}
          />
        ) : (
          <Route path="/" element={<Navigate to="details" replace />} />
        )}
      </Routes>
    </CampaignProvider>
  );
}
