import { useQuery } from "@tanstack/react-query";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { TemplateType } from "common/types";

interface PreviewStep {
  method: "injection" | "ai" | "translate";
  message: string;
  // injection step doesn't have a subject
  subject?: string;
}
async function flowTemplatePreviewRequest(
  workspaceId: string,
  accountId: string,
  isAiEnabled: boolean,
  isTranslateEnabled: boolean,
  languageCode: string,
  leadId: string,
  templateContent: { message: string; subject?: string },
  type: TemplateType,
): Promise<PreviewStep[]> {
  const { steps } = await post(
    `workspaces/${workspaceId}/templates-preview?detailed=true`,
    {
      account_id: accountId,
      ai_flag: isAiEnabled,
      translate_flag: isTranslateEnabled,
      source_language: languageCode,
      previewed_lead_id: leadId,
      template_text: templateContent.message,
      ...(templateContent.subject && { subject: templateContent.subject }),
      type,
    },
  );
  return steps;
}

export default function useFlowTemplatePreview({
  templateContent,
  isAiEnabled,
  isTranslateEnabled,
  languageCode,
  leadId,
  type,
  disableQuery,
}: {
  templateContent: { message: string; subject?: string };
  isAiEnabled: boolean;
  isTranslateEnabled: boolean;
  languageCode: string;
  leadId: string;
  type: TemplateType;
  disableQuery: boolean;
}) {
  const { owner } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const {
    data,
    isError: isPreviewError,
    refetch: refetchPreview,
    isLoading: isLoadingPreview,
  } = useQuery({
    queryKey: [
      "preview",
      workspaceId,
      owner,
      isAiEnabled,
      isTranslateEnabled,
      languageCode,
      leadId,
      templateContent,
      type,
    ],
    queryFn: () =>
      flowTemplatePreviewRequest(
        workspaceId,
        owner,
        isAiEnabled,
        isTranslateEnabled,
        languageCode,
        leadId,
        templateContent,
        type,
      ),
    enabled: !!languageCode && !!templateContent.message && !disableQuery,
  });

  let injectContent;
  let translateContent;
  let aiContent;

  if (data) {
    injectContent = {
      ...data.find((step) => step.method === "injection"),
      subject: templateContent.subject,
    };
    aiContent = data.find((step) => step.method === "ai");
    translateContent = data.find((step) => step.method === "translate");
  }

  return {
    aiContent,
    injectContent,
    translateContent,
    isLoadingPreview,
    refetchPreview,
    isPreviewError,
  };
}
