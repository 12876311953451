import React from "react";
import { CopySimple, Info } from "@phosphor-icons/react";
import { toast } from "react-toastify";

import useWorkspaceToken from "common/datahooks/useWorkspaceToken";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

const whiteLabelUrl = import.meta.env.VITE_WHITE_LABEL_URL;

interface WhiteLabelUrlProps {
  goBack: () => void;
  accountId?: string;
}

export default function WhiteLabelUrl({
  goBack,
  accountId = null,
}: WhiteLabelUrlProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { workspaceToken, isLoadingWorkspaceToken } = useWorkspaceToken({
    accountId,
  });

  const URL = `${whiteLabelUrl}${workspaceToken ? `#${workspaceToken}` : ""}`;

  function copyLink() {
    navigator.clipboard.writeText(URL);

    toast.success("Copied URL");
  }

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold  md:text-headline-xl-bold">
        {accountId ? "Reconnect" : "Connect"} URL
      </h3>
      <span className="mb-1 text-black-700">URL</span>
      <button
        type="button"
        className="flex h-12 items-center justify-between gap-x-4 rounded-xl bg-whiteGray px-4 transition-colors duration-300 hover:bg-black-100 disabled:cursor-not-allowed disabled:opacity-50"
        onClick={copyLink}
        disabled={isLoadingWorkspaceToken}
      >
        <span className="line-clamp-1 break-all text-button-16">{URL}</span>

        <CopySimple size={20} />
      </button>

      <RenderIf condition={!!accountId}>
        <span className="mt-1 flex items-center gap-1 text-caption-12-regular text-black-700">
          <Info weight="fill" size={16} />
          This generates a reconnect link for this specific account
        </span>
      </RenderIf>

      <div className="mt-8 flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          className="flex-1"
          onClick={copyLink}
          size={isTabletOrDesktop ? "md" : "lg"}
          isLoading={isLoadingWorkspaceToken}
        >
          Copy
        </Button>
        <Button
          className="flex-1"
          variant="secondary-black"
          size={isTabletOrDesktop ? "md" : "lg"}
          onClick={goBack}
        >
          Back
        </Button>
      </div>
    </>
  );
}
