import React from "react";
import clsx from "clsx";

import { Dialog, DialogContent } from "common/components/ui/Dialog";

import DeleteWebhook from "./DeleteWebhook";
import { Webhook } from "../../types";
import WebhookForm from "./WebhookForm";
import TestWebhook from "./TestWebhook";

interface WebhookDialogProps {
  webhook: Webhook;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: "edit" | "delete" | "test";
}

export default function WebhookDialog({
  webhook,
  isOpen,
  setIsOpen,
  action,
}: WebhookDialogProps) {
  let component;

  if (action === "delete") {
    component = (
      <DeleteWebhook
        webhook={webhook}
        onBack={() => setIsOpen(false)}
        onSuccess={() => setIsOpen(false)}
      />
    );
  } else if (action === "test") {
    component = (
      <TestWebhook
        webhookId={webhook.id}
        webhookEvents={webhook.events}
        onBack={() => setIsOpen(false)}
      />
    );
  } else if (action === "edit") {
    component = (
      <WebhookForm webhook={webhook} onBack={() => setIsOpen(false)} />
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        className={clsx(
          "max-h-[90%] overflow-auto",
          action === "delete" && "md:w-[448px]",
        )}
      >
        {component}
      </DialogContent>
    </Dialog>
  );
}
