import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import LinkedInLogin, { type LinkedInLoginProps } from "./LinkedInLogin";

interface LinkedInLoginModalProps
  extends Omit<LinkedInLoginProps, "goBack" | "onSuccess"> {
  isOpen: boolean;
  onClose: () => void;
}

export default function LinkedInLoginModal({
  isOpen,
  onClose,
  ...rest
}: LinkedInLoginModalProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Content>
        <LinkedInLogin onSuccess={onClose} {...rest} />
      </Content>
    </Component>
  );
}
