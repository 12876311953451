import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { get, patch } from "../helpers/HTTP";
import { CustomerInfo } from "../types";

async function getCustomerInfo() {
  return (await get("customers")).customer;
}

async function updatePasswordRequest(newPassword: string): Promise<void> {
  await patch("oauth/password", { password: newPassword });
}

async function updateCustomerRequest(
  customerInfo: UpdateCustomerInfo,
): Promise<void> {
  const { firstName, lastName, email } = customerInfo;

  await patch("customers", {
    first_name: firstName,
    last_name: lastName,
    email,
  });
}

export default function useCustomer({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const {
    data: customerInfo,
    refetch,
    isLoading: isLoadingCustomer,
  } = useQuery<CustomerInfo>({
    queryKey: ["customerInfo"],
    queryFn: getCustomerInfo,
    refetchOnMount: false,
    enabled: !disableQuery,
  });

  const {
    mutateAsync: updateCustomer,
    isPending: isCustomerUpdating,
    error: updateCustomerError,
    reset: resetUpdateCustomer,
  } = useMutation({
    mutationFn: ({ email, firstName, lastName }: UpdateCustomerInfo) =>
      updateCustomerRequest({ email, firstName, lastName }),
    onSuccess: (_data, variables) => {
      const successMessage =
        variables.email !== undefined ? "Email address" : "Full name";
      toast.success(`${successMessage} updated`);
      refetch();
    },
  });

  const { mutateAsync: updatePassword, isPending: isUpdatingPassword } =
    useMutation({
      mutationFn: ({ newPassword }: { newPassword: string }) =>
        updatePasswordRequest(newPassword),
      onSuccess: () => {
        toast.success("Password updated");
      },
    });

  return {
    customerInfo,
    isLoadingCustomer,
    updatePassword,
    isUpdatingPassword,
    updateCustomer,
    isCustomerUpdating,
    updateCustomerError,
    resetUpdateCustomer,
  };
}

interface UpdateCustomerInfo {
  phone?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}
