import React, { useState } from "react";
import clsx from "clsx";
import { Info, XCircle } from "@phosphor-icons/react";
import CodeMirror from "@uiw/react-codemirror";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

import useWebhookEventsMutations from "../../datahooks/useWebhookEventsMutations";
import WebhookEventsSelect from "./WebhookEventsSelect";

export default function TestWebhook({
  webhookId,
  webhookEvents,
  onBack,
}: {
  webhookId: string;
  webhookEvents: string[];
  onBack: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { testWebhook, isTestingWebhook, testWebhookResult } =
    useWebhookEventsMutations();
  const [selectedEvent, setSelectedEvent] = useState<string>();

  function onTest() {
    testWebhook({ webhookId, event: selectedEvent });
  }

  const codeMirrorProps = {
    height: "236px",
    theme: "dark",
    basicSetup: { lineNumbers: false, highlightActiveLine: false },
    editable: false,
  } as const;

  let isSuccess = false;
  let feedbackMessage;
  if (testWebhookResult) {
    if (typeof testWebhookResult.response.status === "number") {
      isSuccess =
        testWebhookResult.response.status >= 200 &&
        testWebhookResult.response.status < 300;
      feedbackMessage = isSuccess
        ? "Successfully received OK response from the webhook"
        : `Request failed with status code ${testWebhookResult.response.status}`;
    } else {
      feedbackMessage = "Request failed, endpoint doesn't exist";
    }
  }

  const testResultComponent = testWebhookResult && (
    <>
      <div
        className={clsx(
          "mb-4 flex gap-x-2 gap-y-3 rounded-2xl px-4 py-3 text-caption-12-regular md:mt-6",
          isSuccess ? "bg-green-200" : "bg-red-200",
        )}
      >
        {isSuccess ? (
          <Info size={16} weight="fill" />
        ) : (
          <XCircle size={16} weight="fill" />
        )}
        {feedbackMessage}
      </div>
      <span className="mb-2 text-body-14-bold text-black-700">
        Request sent
      </span>
      <CodeMirror
        value={`Headers\n${JSON.stringify(testWebhookResult.request.headers, null, 2)}\n\nBody\n${JSON.stringify(testWebhookResult.request.body, null, 2)}`}
        {...codeMirrorProps}
      />
      <span className="mb-2 mt-4 text-body-14-bold text-black-700">
        Response received
      </span>
      <CodeMirror
        value={`Headers\n${JSON.stringify(testWebhookResult.response.headers, null, 2)}\n\nBody\n${JSON.stringify(testWebhookResult.response.body, null, 2)}`}
        {...codeMirrorProps}
      />
    </>
  );

  return (
    <>
      <h3 className="mb-6 text-center text-headline-2xl-bold capitalize md:mb-4 md:text-headline-xl-bold">
        Test your webhook
      </h3>
      <span className="mb-1 text-body-14-regular text-black-700">Events</span>
      <WebhookEventsSelect
        events={webhookEvents}
        selection={selectedEvent}
        setSelection={(newEvent) => setSelectedEvent(newEvent)}
        isMultiSelect={false}
      />
      {!isTabletOrDesktop && testResultComponent}
      <div className="mt-4 flex flex-col-reverse gap-2 md:flex-row md:gap-4">
        <Button
          variant="secondary-black"
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          onClick={onBack}
          disabled={isTestingWebhook}
        >
          Cancel
        </Button>
        <Button
          variant="primary-black"
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          disabled={!selectedEvent}
          isLoading={isTestingWebhook}
          onClick={onTest}
        >
          Test Webhook
        </Button>
      </div>
      {isTabletOrDesktop && testResultComponent}
    </>
  );
}
