import React from "react";

import { CampaignType } from "common/types";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Targeting as TargetingType } from "../../../types";
import Search from "./Search";
import Post from "./Post";
import GroupAndEvent from "./GroupAndEvent";
import List from "./List";
import ReconnectAccount from "./ReconnectAccount";

interface TargetingProps {
  targeting: TargetingType;
}

export default function Targeting({ targeting }: TargetingProps) {
  const { accounts } = useSelectedWorkspaceContext();
  const { owner, type } = useCampaignContext();
  const account = accounts.find(({ id }) => id === owner);

  if (account.state !== "LoggedIn") {
    return <ReconnectAccount account={account} />;
  }

  const targetingSetup: Record<CampaignType, React.ReactNode> = {
    search: <Search targeting={targeting} />,
    event: <GroupAndEvent type="event" />,
    group: <GroupAndEvent type="group" />,
    post: <Post />,
    list: <List />,
    ai: null,
  };

  return targetingSetup[type];
}
