import { useQuery } from "@tanstack/react-query";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { SearchSuggestion } from "common/types";

async function getSearchSuggestionsRequest(
  workspaceId: string,
  campaignId: string,
): Promise<SearchSuggestion[]> {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/predefined-search-campaigns`,
    )
  ).suggestions;
}

export default function useSearchSuggestions() {
  const { id: campaignId } = useCampaignContext();

  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: searchSuggestions, isLoading: isLoadingSearchSuggestions } =
    useQuery({
      queryKey: ["searchSuggestions", campaignId, workspaceId],
      queryFn: () => getSearchSuggestionsRequest(workspaceId, campaignId),
    });

  return { searchSuggestions, isLoadingSearchSuggestions };
}
