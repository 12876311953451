import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import { Template } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import {
  DialogContent,
  Dialog,
  DialogTrigger,
} from "common/components/ui/Dialog";
import TemplateDetailsForm from "common/components/TemplateDetailsForm";
import { Button } from "common/components/ui/Button";

import TypeSelection from "./TypeSelection";

export default function CreateTemplate({
  className = undefined,
}: {
  className?: string;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);
  const [templateType, setTemplateType] = useState(null);

  const template: Template = {
    type: templateType,
    message: "",
    name: "",
    subject: "",
  };

  function reset() {
    setTemplateType(null);
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen} onAfterClose={reset}>
      <Trigger asChild>
        <Button
          leftIcon={<PlusCircle weight="fill" />}
          onClick={() => setIsOpen(true)}
          data-cy="create-template-button"
          className={className}
        >
          Create template
        </Button>
      </Trigger>

      <Content>
        {templateType ? (
          <TemplateDetailsForm
            template={template}
            goBack={() => {
              setIsOpen(false);
              setTimeout(reset, 300);
            }}
          />
        ) : (
          <TypeSelection
            goToNextStep={(selectedType) => setTemplateType(selectedType)}
          />
        )}
      </Content>
    </Component>
  );
}
