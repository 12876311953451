import React, { useState } from "react";
import { Files, Image, PencilSimple } from "@phosphor-icons/react";

import { CampaignType, ProfileSearchParams } from "common/types";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import { useCampaignContext } from "common/helpers/CampaignContext";

import SearchTargetingLabels from "./SearchTargetingLabels";
import EventTargetingLabel from "./EventTargetingLabel";
import GroupTargetingLabel from "./GroupTargetingLabel";
import { EventTargeting, GroupTargeting } from "../../types";
import useTargeting from "../../datahooks/useTargeting";
import ResetTargeting from "../ResetTargeting";

interface TargetingPreviewProps {
  shouldShowEditButton?: boolean;
  isEditDisabled?: boolean;
}

export default function TargetingPreview({
  shouldShowEditButton = false,
  isEditDisabled = false,
}: TargetingPreviewProps) {
  const { type } = useCampaignContext();
  const { targeting, isLoadingTargeting } = useTargeting();
  const [isResetTargetingOpen, setIsResetTargetingOpen] = useState(false);

  const targetingTagOptions: Record<CampaignType, React.ReactNode> = {
    search: (
      <SearchTargetingLabels
        searchParams={targeting as ProfileSearchParams}
        isLoading={isLoadingTargeting}
      />
    ),
    event: (
      <EventTargetingLabel
        eventId={(targeting as EventTargeting)?.event_id}
        isLoading={isLoadingTargeting}
      />
    ),
    group: (
      <GroupTargetingLabel
        groupId={(targeting as GroupTargeting)?.group_id}
        isLoading={isLoadingTargeting}
      />
    ),
    post: (
      <Tag size="lg" variant="primary-black" leftIcon={<Image weight="fill" />}>
        Post Campaign
      </Tag>
    ),
    list: (
      <Tag size="lg" variant="primary-black" leftIcon={<Files weight="fill" />}>
        List Campaign
      </Tag>
    ),
    ai: null,
  };

  return (
    <section className="mb-4 flex flex-col items-start rounded-2xl border border-black-200 px-3 py-4 md:px-4">
      <div className="mb-2 flex w-full justify-between border-b border-black-200 pb-2">
        <div>
          <h3 className="text-button-16">Targeting</h3>
          <p className="text-caption-12-regular text-black-500">
            Your targeting configuration for this campaign
          </p>
        </div>

        <RenderIf condition={shouldShowEditButton}>
          <Button
            variant="secondary-black"
            intent="iconOnly"
            disabled={isEditDisabled}
            onClick={() => setIsResetTargetingOpen(true)}
          >
            <PencilSimple />
          </Button>
          <ResetTargeting
            isOpen={isResetTargetingOpen}
            setIsOpen={setIsResetTargetingOpen}
          />
        </RenderIf>
      </div>

      {targetingTagOptions[type]}
    </section>
  );
}
