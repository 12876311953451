import React, { ComponentProps } from "react";
import { Star } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { getProfileSubtitle } from "common/helpers/utils";
import FavoriteLead from "common/components/FavoriteLead";

import GroupConversationAvatar from "./GroupConversationAvatar";
import { Conversation } from "../types";
import { getGroupParticipantsString } from "../utils";
import RenderIf from "../../../common/components/RenderIf";
import OriginsInfo from "./OriginsInfo";

interface ParticipantInfoProps {
  conversation: Conversation;
  profileSize?: ComponentProps<typeof ProfileImage>["size"];
  showOrigins?: boolean;
  showFavorite?: boolean;
  isConversation?: boolean;
}

export default function ParticipantInfo({
  conversation,
  profileSize = "sm",
  showOrigins = false,
  showFavorite = false,
  isConversation = false,
}: ParticipantInfoProps) {
  const groupParticipantsString = getGroupParticipantsString(conversation);
  const { participants } = conversation;

  const renderFavorite = () => {
    if (!isConversation && groupParticipantsString) {
      return;
    }

    const { is_lead: isLead, favorite: isFavorite } = participants[0];

    if (!showFavorite) {
      if (isLead && isFavorite) {
        return (
          <Star
            className="min-h-3.5 min-w-3.5 text-yellow-500 transition-colors duration-200"
            weight="fill"
            size={14}
          />
        );
      }

      return;
    }

    return (
      <FavoriteLead
        leadId={participants[0].id}
        isLead={participants[0].is_lead}
        isFavorite={participants[0].favorite}
      />
    );
  };

  const isOriginAvailable =
    showOrigins &&
    participants[0]?.origins[0]?.name &&
    !groupParticipantsString;

  return (
    <div className="flex flex-row items-center gap-1">
      {groupParticipantsString ? (
        <GroupConversationAvatar participants={participants} />
      ) : (
        <ProfileImage size={profileSize} src={participants[0].picture_url} />
      )}

      <div className="ml-2 flex flex-col items-start justify-center text-start">
        <div className="flex flex-row items-center justify-center gap-x-1.5">
          <span className="line-clamp-1 text-button-14">
            {groupParticipantsString || participants[0].full_name}
          </span>

          {renderFavorite()}
        </div>

        <p className="flex flex-row flex-wrap items-center gap-1 text-caption-12-regular text-black-600">
          <span className="line-clamp-1 max-w-[275px]">
            {groupParticipantsString
              ? `${participants.length} members`
              : getProfileSubtitle(participants[0])}
          </span>

          <RenderIf condition={isOriginAvailable}>
            <OriginsInfo origins={participants[0].origins} />
          </RenderIf>
        </p>
      </div>
    </div>
  );
}
