import { maxLength, minLength, object, string, trim, pipe } from "valibot";

// eslint-disable-next-line import/prefer-default-export
export const FlowNameSchema = object({
  name: pipe(
    string(),
    trim(),
    minLength(1, "Flow name is required"),
    maxLength(20, "Flow name can't be more than 20 characters long"),
  ),
});
