import { useMutation } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { post } from "../helpers/HTTP";

async function resetUnreadMessagesRequest(workspaceId: string) {
  await post(`workspaces/${workspaceId}/conversations/unread`);
}

export default function useUnreadMessagesCount() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { mutate: resetUnreadMessages } = useMutation({
    mutationFn: () => resetUnreadMessagesRequest(workspaceId),
  });

  return {
    resetUnreadMessages,
  };
}
