import React from "react";
import clsx from "clsx";
import { Info } from "@phosphor-icons/react";

import RenderIf from "common/components/RenderIf";
import Progress from "common/components/ui/Progress";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { useCampaignContext } from "common/helpers/CampaignContext";

interface StatsItemProps {
  label: string;
  value: string | number;
  tooltipText: string;
  progress?: number;
}

function StatItem({
  label,
  value,
  tooltipText,
  progress = null,
}: StatsItemProps) {
  return (
    <div
      className={clsx(
        "flex-1 rounded-3xl bg-whiteGray px-5 pt-5",
        progress !== null ? "pb-2" : "pb-8",
      )}
    >
      <div className="mb-4 flex items-center gap-x-1">
        <h4>{label}</h4>
        <Tooltip>
          <TooltipTrigger>
            <Info className="size-5 fill-black-500" weight="fill" />
          </TooltipTrigger>
          <TooltipContent>{tooltipText}</TooltipContent>
        </Tooltip>
      </div>
      <span className="text-headline-5xl-regular">{value}</span>

      <RenderIf condition={progress !== null}>
        <Progress value={progress} className="mt-2" />
      </RenderIf>
    </div>
  );
}

export default function CampaignStats() {
  const {
    metrics: {
      accepted_connections: acceptedConnections,
      sent_connections: sentConnections,
      sent_inmails: sentInmails,
      message_requests: messageRequests,
      inmail_replies: inmailReplies,
      replies,
    },
    completion,
    outreach_type: outreachType,
  } = useCampaignContext();

  const leadsGained =
    outreachType === "connect" ? acceptedConnections + inmailReplies : replies;

  const totalInteractions = sentConnections + sentInmails + messageRequests;

  return (
    <section className="mb-6 flex flex-col gap-3 md:flex-row">
      <StatItem
        label="Leads gained"
        value={leadsGained}
        tooltipText="How many leads you gained in this timeframe. A lead is anyone who has accepted a connection request, or who has replied to any of your inmails, messages, or message requests"
      />
      <StatItem
        label="Total interactions done"
        value={totalInteractions}
        tooltipText="The total sum of all interactions you performed through Aimfox campaigns. Includes profile views, connection requests, inmails, messages and message requests"
      />
      <StatItem
        label="Campaign Progress"
        value={`${Math.floor(completion * 100)}%`}
        tooltipText="The current progress of your campaign. The campaign is considered completed once it has sent out all invites, inmails, and messages"
        progress={completion * 100}
      />
    </section>
  );
}
