import React, { useState } from "react";

import CSVFileUpload from "common/components/CSVFileUpload";
import useLinkedInUrls from "common/hooks/useLinkedInUrls";

import useTargeting from "../../../../datahooks/useTargeting";
import Stepper from "../../Stepper";
import IgnoreProfilesModal from "./IgnoreProfilesModal";

const maxUrls = 10000;

export default function List() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { linkedInUrls, setLinkedInUrls, invalidLines, validUrls } =
    useLinkedInUrls();
  const { setTargeting, isSettingTargeting } = useTargeting();

  function updateTargeting() {
    setTargeting({
      campaignTargeting: {
        identifiers: validUrls.map((url) => {
          const urlComponents = url.split("/");
          return urlComponents[
            urlComponents.findIndex((string) => string === "in") + 1
          ];
        }),
      },
    });
  }

  function goToNextStep() {
    if (invalidLines.length) {
      setIsDialogOpen(true);
    } else {
      updateTargeting();
    }
  }

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">Custom Campaign</h2>
      <p className="mb-4 text-black-500 md:mb-10">
        List campaigns allow you to create an extremely targeted audience by
        providing a list of LinkedIn account URLs. This can be either a series
        of links, like a column from one of your marketing sheets, or a .CSV
        file
      </p>
      <div className="mx-auto w-full max-w-[750px]">
        <CSVFileUpload
          lineItems={linkedInUrls}
          setLineItems={setLinkedInUrls}
          errorLines={invalidLines}
          maxAllowedItems={maxUrls}
        />
      </div>
      <Stepper
        selectedIndex={0}
        goToNextStep={goToNextStep}
        isNextButtonLoading={isSettingTargeting}
        isNextStepDisabled={!validUrls.length}
      />
      <IgnoreProfilesModal
        isOpen={isDialogOpen}
        updateTargeting={updateTargeting}
        invalidUrlsCount={invalidLines.length}
        isSettingTargeting={isSettingTargeting}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}
