import React from "react";

import Switch from "common/components/ui/Switch";

import useWhiteLabelMutations from "../../datahooks/useWhiteLabelMutations";

interface WhiteLabelExtensionLoginProps {
  isEnabled: boolean;
}

export default function WhiteLabelExtensionLogin({
  isEnabled,
}: WhiteLabelExtensionLoginProps) {
  const { updateWhiteLabel } = useWhiteLabelMutations();

  function handleUpdate(checked: boolean) {
    updateWhiteLabel({
      use_chrome_extension: checked,
    });
  }

  return (
    <div className="flex items-center gap-x-3 border-b border-black-200 max-md:py-4 md:h-16 md:px-3">
      <div className="flex flex-1 flex-col max-md:gap-2 md:flex-row">
        <h4 className="text-black-600 md:w-full md:max-w-[224px]">
          Extension login
        </h4>

        <span className="text-button-14 md:hidden">
          {isEnabled ? "ON" : "OFF"}
        </span>
      </div>

      <Switch
        className="ml-auto"
        checked={isEnabled}
        onCheckedChange={handleUpdate}
      />
    </div>
  );
}
