import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { LinkedInProfile, WorkspaceAccount } from "common/types";
import useAimfoxExtension from "common/datahooks/useAimfoxExtension";
import useChromeExtension from "common/hooks/useChromeExtension";
import ProxySelect from "common/components/ProxySelect";
import { Button } from "common/components/ui/Button";

import ExtensionLoginLoader from "./ExtensionLoginLoader";
import LinkedinInfo from "./LinkedinInfo";

const chromeExtensionId = import.meta.env.VITE_CHROME_EXTENSION_ID;

interface ExtensionLoginProps {
  account: WorkspaceAccount;
  countryCode: string;
  onBack: () => void;
  onLocationChange: (countryCode: string) => void;
}

interface LinkedInResponseData {
  data?: {
    plainId: number | string;
  };
  included: Array<{
    firstName?: string;
    lastName?: string;
    publicIdentifier?: string;
    occupation?: string;
    picture?: {
      rootUrl: string;
      artifacts: Array<{
        fileIdentifyingUrlPathSegment: string;
      }>;
    };
  }>;
}

function parseUserDataResponse(
  response: LinkedInResponseData,
): Partial<LinkedInProfile> {
  try {
    if (!response?.data?.plainId || !response.included?.length) {
      throw new Error("Invalid LinkedIn response format");
    }

    const id = String(response.data.plainId);

    // Merge all included objects into a single object
    const userData = response.included.reduce<
      LinkedInResponseData["included"][0]
    >(
      (prev, cur) => ({
        ...prev,
        ...cur,
      }),
      {},
    );

    const pictureUrl = `${userData.picture.rootUrl}${userData.picture.artifacts[1].fileIdentifyingUrlPathSegment}`;

    return {
      id,
      first_name: userData.firstName,
      last_name: userData.lastName,
      public_identifier: userData.publicIdentifier,
      picture_url: pictureUrl,
      occupation: userData.occupation,
    };
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
}

export default function ExtensionLogin({
  account,
  countryCode,
  onBack,
  onLocationChange,
}: ExtensionLoginProps) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const [linkedinAccount, setLinkedinAccount] =
    useState<Partial<LinkedInProfile>>(null);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);

  const { isExtensionAvailable } = useChromeExtension();

  const {
    connectLinkedInAccount,
    getAccountFromCookies,
    isConnectingAccount,
    isGettingAccount,
  } = useAimfoxExtension(workspaceId, account?.id, countryCode);

  useEffect(() => {
    if (!isExtensionAvailable) {
      setLinkedinAccount(null);
      return;
    }

    setIsLoadingUserInfo(true);

    window.chrome.runtime.sendMessage(
      chromeExtensionId,
      { command: "getUserInfo" },
      async ({ response }) => {
        const data = parseUserDataResponse(response);
        setLinkedinAccount(data);
        setIsLoadingUserInfo(false);
      },
    );
  }, [isExtensionAvailable]);

  async function handleConnectLinkedInAccount(): Promise<void> {
    // First get account ID from cookies
    window.chrome.runtime.sendMessage(
      chromeExtensionId,
      { command: "getCookies" },
      async ({ response }) => {
        try {
          const profile = await getAccountFromCookies(response);
          // Then connect the account using the retrieved ID
          await connectLinkedInAccount({
            accountId: profile.id,
            account: linkedinAccount as LinkedInProfile,
          });
        } catch (err) {
          Sentry.setExtra("error", err);
          Sentry.setExtra("error_json", JSON.stringify(err));
          Sentry.captureMessage(
            "Chrome Extension Cookies Login - Failed to add account",
          );
        }
      },
    );
  }

  let mainComponent;

  if (isLoadingUserInfo) {
    mainComponent = <ExtensionLoginLoader />;
  } else if (!linkedinAccount && !isGettingAccount) {
    mainComponent = (
      <div className="mb-4 rounded-xl border border-red-300 bg-red-50 p-4 text-center text-body-14-bold text-red-500">
        Linkedin account not found
      </div>
    );
  } else {
    mainComponent = (
      <>
        {/* User info card */}
        <LinkedinInfo
          pictureUrl={linkedinAccount.picture_url}
          fullName={`${linkedinAccount.first_name} ${linkedinAccount.last_name}`}
          occupation={linkedinAccount.occupation}
        />

        <div className="mt-4 flex flex-col gap-y-2">
          <span className="text-black-700">Proxy (login location)</span>
          <ProxySelect countryCode={countryCode} onChange={onLocationChange} />
        </div>
      </>
    );
  }

  return (
    <>
      <h2 className="mb-4 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Select a proxy for your account
      </h2>

      {mainComponent}

      {/* Controls for handling back or connect actions */}
      <div className="mt-4 flex gap-x-2 md:gap-x-4">
        <Button
          variant="secondary-black"
          className="flex-1"
          type="button"
          onClick={onBack}
        >
          Back
        </Button>

        <Button
          variant="primary-black"
          className="flex-1"
          isLoading={isConnectingAccount || isGettingAccount}
          disabled={!linkedinAccount}
          onClick={handleConnectLinkedInAccount}
        >
          Connect
        </Button>
      </div>
    </>
  );
}
