import { useEffect, useState } from "react";

const chromeExtensionId = import.meta.env.VITE_CHROME_EXTENSION_ID;

interface UseChromeExtensionReturn {
  isExtensionAvailable: boolean;
  isCheckingExtension: boolean;
}

export default function useChromeExtension(): UseChromeExtensionReturn {
  const [isExtensionAvailable, setIsExtensionAvailable] = useState(false);
  const [isCheckingExtension, setIsCheckingExtension] = useState(true);

  useEffect(() => {
    const checkExtension = () => {
      if (
        typeof window.chrome === "undefined" ||
        !window.chrome?.runtime?.sendMessage
      ) {
        setIsExtensionAvailable(false);
        setIsCheckingExtension(false);
        return;
      }

      window.chrome.runtime.sendMessage(
        chromeExtensionId,
        { command: "ping" },
        (response) => {
          const isAvailable = !window.chrome.runtime.lastError && response;
          setIsExtensionAvailable(isAvailable);
          setIsCheckingExtension(false);
        },
      );
    };

    // Check initially
    checkExtension();

    // Listen for extension install/uninstall events
    const handleInstall = (info: chrome.management.ExtensionInfo) => {
      if (info.id === chromeExtensionId) {
        checkExtension();
      }
    };

    const handleUninstall = (id: string) => {
      if (id === chromeExtensionId) {
        checkExtension();
      }
    }; 

    window.chrome?.management?.onInstalled?.addListener(handleInstall);
    window.chrome?.management?.onUninstalled?.addListener(handleUninstall);
    window.addEventListener("focus", checkExtension);

    return () => {
      window.chrome?.management?.onInstalled?.removeListener(handleInstall);
      window.chrome?.management?.onUninstalled?.removeListener(handleUninstall);
      window.removeEventListener("focus", checkExtension);
    };
  }, []);

  return { isExtensionAvailable, isCheckingExtension };
} 