import React, { JSX } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import RenderIf from "common/components/RenderIf";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import Alert from "common/components/ui/Alert";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";
import { useCampaignContext } from "common/helpers/CampaignContext";

import CampaignOverview from "./CampaignOverview";
import CampaignAudience from "./CampaignAudience";
import CampaignSchedule from "./CampaignSchedule";
import { getFlowTitle, getSortedAndFilteredFlows } from "../../utils";
import TargetingPreview from "../TargetingPreview";
import FlowDetails from "./FlowDetails";

interface CampaignNavItem {
  label: string;
  path: string;
  component: JSX.Element;
}

export default function CampaignDetails() {
  const {
    state,
    owner,
    flows,
    inmail_optimization: isInMailOptimizationEnabled,
  } = useCampaignContext();

  const [activeTab, setActiveTab] = useQueryParam(
    "activeTab",
    withDefault(StringParam, "overview"),
    {
      removeDefaultsFromUrl: true,
      updateType: "replaceIn",
    },
  );

  const { accounts } = useSelectedWorkspaceContext();
  const { license, state: accountState } = accounts.find(
    ({ id }) => id === owner,
  );

  const isCampaignStopped =
    state === "ACTIVE" && (!license || accountState !== "LoggedIn");

  const sortedAndFilteredFlows = getSortedAndFilteredFlows(
    flows,
    isInMailOptimizationEnabled,
  );

  const flowNavMenus: CampaignNavItem[] = [];

  function goToFlowTab(flowId: string) {
    setActiveTab(`flows/${flowId}`);
  }

  sortedAndFilteredFlows.forEach((flow) => {
    flowNavMenus.push({
      label: getFlowTitle(flow),
      path: `flows/${flow.id}`,
      component: <FlowDetails flow={flow} key={flow.id} />,
    });
  });

  const submenus = [
    {
      label: "Overview",
      path: "overview",
      component: (
        <CampaignOverview
          flows={sortedAndFilteredFlows}
          goToFlowTab={goToFlowTab}
        />
      ),
    },
    {
      label: "Audience",
      path: "audience",
      component: <CampaignAudience />,
    },
    ...flowNavMenus,
    {
      label: "Schedule",
      path: "schedule",
      component: <CampaignSchedule />,
    },
  ];

  return (
    <>
      <RenderIf condition={isCampaignStopped}>
        <Alert
          description={`This campaign stopped because the working account ${
            !license
              ? "does not have an Outreach Seat. Assign a seat to resume"
              : "is disconnected. Reconnect the account to resume"
          }`}
          className="mb-4"
        />
      </RenderIf>

      <TargetingPreview />
      <Tabs value={activeTab}>
        <TabsList className="mb-4 pb-4 max-md:-mx-4 max-md:px-4">
          {submenus.map(({ label, path }) => (
            <TabsTrigger
              key={path}
              value={path}
              onClick={() => setActiveTab(path)}
            >
              {label}
            </TabsTrigger>
          ))}
        </TabsList>

        {submenus.map(({ component, path }) => (
          <TabsContent key={path} value={path}>
            {component}
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
}
