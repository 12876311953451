import { useMutation } from "@tanstack/react-query";

import { post } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useCampaignFlow from "./useCampaignFlow";
import { useCampaignFlowContext } from "../context/CampaignFlowContext";

async function detectLanguageRequest(
  workspaceId: string,
  campaignId: string,
  text: string,
): Promise<string> {
  return (
    await post(
      `workspaces/${workspaceId}/campaigns/${campaignId}/detect-language`,
      {
        template_text: text,
      },
    )
  ).detected_language.code;
}

export default function useDetectLanguage() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { id: campaignId } = useCampaignContext();

  const { flow } = useCampaignFlowContext();
  const { updateFlow } = useCampaignFlow();

  const { mutateAsync: detectLanguage } = useMutation({
    mutationFn: ({ text }: { text: string }) =>
      detectLanguageRequest(workspaceId, campaignId, text),
    onSuccess: (languageCode) =>
      updateFlow({
        flowId: flow.id,
        updates: {
          source_language: languageCode,
          is_manual_language: false,
        },
      }),
  });

  return { detectLanguage };
}
