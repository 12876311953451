import React from "react";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import TemplateDetailsForm from "common/components/TemplateDetailsForm";
import { CreatedTemplate, Template, TemplateType } from "common/types";

interface NewTemplateProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  type: TemplateType;
  onCreated: (template: CreatedTemplate) => void;
}

export default function NewTemplate({
  isOpen,
  setIsOpen,
  type,
  onCreated,
}: NewTemplateProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const template: Template = {
    type,
    message: "",
    name: "",
    subject: "",
  };

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content>
        <TemplateDetailsForm
          goBack={() => setIsOpen(false)}
          template={template}
          onCreated={onCreated}
        />
      </Content>
    </Component>
  );
}
