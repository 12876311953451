import React from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { typedObjectEntries } from "common/helpers/utils";

export default function LeadSource({
  origins,
}: {
  origins: { id: string; name: string }[];
}) {
  const label = (
    <span className="text-button-12">
      {origins.length > 1 ? "Multiple sources" : origins[0].name}
    </span>
  );
  const groupedOrigins = origins.reduce<Record<string, number>>(
    (accumulator, { name }) => {
      if (accumulator[name]) {
        accumulator[name] += 1;
      } else {
        accumulator[name] = 1;
      }
      return accumulator;
    },
    {},
  );
  return (
    <div className="flex gap-x-1">
      <span className="text-caption-12-regular text-black-600">Origin:</span>
      {origins.length > 1 ? (
        <Tooltip>
          <TooltipTrigger asChild>{label}</TooltipTrigger>
          <TooltipContent className="w-fit max-w-64">
            {typedObjectEntries(groupedOrigins)
              .map(([name, count]) => (count > 1 ? `${name} x${count}` : name))
              .join(", ")}
          </TooltipContent>
        </Tooltip>
      ) : (
        label
      )}
    </div>
  );
}
