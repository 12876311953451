import React from "react";
import { X } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";
import useCampaigns from "common/datahooks/useCampaigns";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import LabelTag from "common/components/LabelTag";

import CampaignBadge from "./CampaignBadge";

interface ActiveFiltersProps {
  selectedAccountIds: string[];
  selectedLabelIds: string[];
  selectedCampaignIds: string[];
  onRemoveAccountFilter: (accountId: string) => void;
  onRemoveLabelFilter: (labelId: string) => void;
  onRemoveCampaignFilter: (campaignId: string) => void;
}

export default function ActiveFilters({
  selectedAccountIds,
  selectedLabelIds,
  selectedCampaignIds,
  onRemoveAccountFilter,
  onRemoveLabelFilter,
  onRemoveCampaignFilter,
}: ActiveFiltersProps) {
  const { accounts } = useSelectedWorkspaceContext();
  const { workspaceLabels } = useWorkspaceLabels();
  const { campaigns } = useCampaigns();

  const hasActiveFilters =
    selectedAccountIds?.length > 0 ||
    selectedLabelIds?.length > 0 ||
    selectedCampaignIds?.length > 0;

  if (!hasActiveFilters) {
    return null;
  }

  return (
    <div className="mt-4 flex flex-row flex-wrap gap-2 md:mt-0">
      {selectedAccountIds.map((selectedAccountId) => {
        const account = accounts.find(({ id }) => id === selectedAccountId);
        const { id, full_name: fullName } = account;

        return (
          <LinkedInAccountsTag
            key={id}
            accountIds={[id]}
            size="lg"
            rightComponent={
              <button
                type="button"
                aria-label={`Remove account filter for ${fullName}`}
                onClick={() => onRemoveAccountFilter(id)}
              >
                <X size={16} className="fill-black-950" />
              </button>
            }
          />
        );
      })}

      {selectedCampaignIds.map((selectedCampaignId) => {
        const campaign = campaigns.find(({ id }) => id === selectedCampaignId);
        return (
          <CampaignBadge
            key={campaign.id}
            campaign={campaign}
            removeFilter={onRemoveCampaignFilter}
          />
        );
      })}

      {selectedLabelIds.map((selectedLabelId) => {
        const label = workspaceLabels?.find(({ id }) => id === selectedLabelId);
        const { id } = label;

        return (
          <LabelTag
            key={id}
            size="lg"
            label={label}
            onRemove={() => onRemoveLabelFilter(id)}
          />
        );
      })}
    </div>
  );
}
