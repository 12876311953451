import React, {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react";
import { Info } from "@phosphor-icons/react";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "common/helpers/utils";

const alertVariants = cva(
  "relative rounded-20 border bg-white py-6 pl-14 pr-4 [&_svg]:absolute [&_svg]:left-4 [&_svg]:top-6 [&_svg]:size-6",
  {
    variants: {
      variant: {
        default: "border-black-200",
        danger: "border-red-400 text-red-500",
        success: "border-green-400 text-green-500",
        warning: "border-yellow-400",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

type AlertProps = ComponentPropsWithoutRef<"div"> &
  VariantProps<typeof alertVariants> & {
    title?: string;
    description: string;
    icon?: ReactNode;
  };

function Alert(
  {
    description,
    title = "",
    variant,
    className,
    icon = null,
    ...props
  }: AlertProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={cn(alertVariants({ variant, className }))}
      {...props}
    >
      {icon || <Info weight="fill" />}

      {title && <h5 className="mb-1 text-button-16 text-black-950">{title}</h5>}
      <p className="text-button-14 text-black-700">{description}</p>
    </div>
  );
}

Alert.displayName = "Alert";

export default forwardRef(Alert);
