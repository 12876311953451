import React from "react";
import { ParentSize } from "@visx/responsive";

import { Metrics } from "common/types";
import { typedObjectKeys } from "common/helpers/utils";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import RenderIf from "common/components/RenderIf";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import Chart from "./Chart";

import NoDataImage from "assets/images/empty-placeholders/search-campaign.png";

interface StatisticsGraphProps {
  metrics: (Metrics & { timestamp: number })[];
  metricsSum: Metrics;
  selectedMetric: keyof Metrics;
  requestError: unknown;
  refetchMetrics: () => void;
  isLoading: boolean;
  isHourly: boolean;
}

export default function StatisticsGraph({
  metrics,
  metricsSum,
  selectedMetric,
  requestError,
  isLoading,
  refetchMetrics,
  isHourly,
}: StatisticsGraphProps) {
  const isEmpty = typedObjectKeys(metricsSum).every(
    (metricKey) => !metricsSum[metricKey],
  );

  return requestError ? (
    <ErrorPlaceholder
      onRetry={refetchMetrics}
      errorMessage="Could not display graph data"
    />
  ) : (
    <>
      <ParentSize>
        {({ width, height }) => (
          <Chart
            metrics={metrics}
            selectedMetric={selectedMetric}
            width={width}
            height={height}
            isEmpty={isEmpty}
            isHourly={isHourly}
          />
        )}
      </ParentSize>
      <RenderIf condition={!isLoading && isEmpty}>
        <div className="absolute inset-0 flex flex-col pb-12">
          <EmptyPlaceholder
            title="No data to display"
            subtitle="The selected timeframe does not have any data to show"
            imageSrc={NoDataImage}
          />
        </div>
      </RenderIf>
    </>
  );
}
