import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { get } from "common/helpers/HTTP";

import { Webhook } from "../types";

async function getWebhooksRequest(workspaceId: string): Promise<Webhook[]> {
  return (await get(`workspaces/${workspaceId}/webhooks`)).webhooks;
}

export default function useWebhooks() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { webhooksKeys } = getQueryKeys(workspaceId);

  const {
    data: webhooks,
    isLoading: isLoadingWebhooks,
    isError: isWebhooksError,
    refetch: refetchWebhooks,
  } = useQuery({
    queryKey: webhooksKeys.list,
    queryFn: () => getWebhooksRequest(workspaceId),
  });

  return {
    webhooks,
    isLoadingWebhooks,
    isWebhooksError,
    refetchWebhooks,
  };
}
