import React, { useRef, useState } from "react";
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from "@chargebee/chargebee-js-react-wrapper";
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import usePaymentSources from "common/datahooks/usePaymentSources";
import { HTTPError } from "common/helpers/HTTP";
import { Button } from "common/components/ui/Button";

interface CreditCardProps {
  onBack: () => void;
  onTokenReceived: (token: string) => void;
}

export default function CreditCard({
  onTokenReceived,
  onBack,
}: CreditCardProps) {
  const cardRef = useRef<ChargebeeComponents>();

  const [isParsingCard, setIsParsingCard] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");

  const [errorsState, setErrorsState] = useState({
    number: null,
    expiry: null,
    cvv: null,
  });

  const {
    isAddingPaymentSource,
    addPaymentSourceError,
    resetAddPaymentSourceError,
  } = usePaymentSources();

  async function getToken(): Promise<string> {
    const { token } = await cardRef.current.tokenize({
      firstName: nameOnCard.trim(),
    });

    return token;
  }

  async function onCreditCardSave() {
    let token;

    try {
      resetAddPaymentSourceError();
      setIsParsingCard(true);
      token = await getToken();
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Error adding card";

      toast.error(errorMessage);

      Sentry.setExtra("error", error);
      Sentry.captureMessage(errorMessage);
    } finally {
      setIsParsingCard(false);
    }

    onTokenReceived(token);
  }

  function onChange(state: ChargebeeCurrentState) {
    setErrorsState((prevState) => ({
      ...prevState,
      [state.field]: state.error?.message,
    }));
  }

  const isLoading = isParsingCard || isAddingPaymentSource;

  return (
    <>
      <div className="mb-4 flex flex-col gap-1">
        <Label htmlFor="cardHolder" size="md">
          Card holder&lsquo;s name
        </Label>

        <Input
          id="cardHolder"
          placeholder="Example: John Doe"
          variant="md"
          value={nameOnCard}
          onChange={(e) => setNameOnCard(e.target.value)}
          onBlur={(e) => {
            setErrorsState((prevState) => ({
              ...prevState,
              name: e.target.value.trim()
                ? null
                : "Card holder's name is required",
            }));
          }}
        />
      </div>

      {/* Note: Known issue in Chargebee (Strict Mode extra iframe) -> https://github.com/chargebee/chargebee-js-wrappers/issues/98 */}
      <CardComponent
        ref={cardRef}
        classes={{ invalid: "invalid-card-input" }}
        fonts={[{ fontFamily: "", src: "" }]}
        // @ts-expect-error: the chargebee type definitions are wrong
        onChange={onChange}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Label htmlFor="cardNumber" size="md">
              Card number
            </Label>

            <CardNumber
              className="chargebee-input-field"
              placeholder="Card number"
            />

            {errorsState.number && (
              <span className="text-caption-12-regular text-red-500">
                {errorsState.number}
              </span>
            )}
          </div>

          <div className="flex flex-row justify-start gap-4">
            <div className="flex w-1/2 flex-col">
              <Label htmlFor="expiryDate" size="md">
                Expiry date
              </Label>

              <CardExpiry className="chargebee-input-field" />

              {errorsState.expiry && (
                <span className="text-caption-12-regular text-red-500">
                  {errorsState.expiry}
                </span>
              )}
            </div>

            <div className="flex w-1/2 flex-col">
              <Label htmlFor="cvc" size="md">
                CVC
              </Label>

              <CardCVV className="chargebee-input-field" />

              {errorsState.cvv && (
                <span className="text-caption-12-regular text-red-500">
                  {errorsState.cvv}
                </span>
              )}
            </div>
          </div>
        </div>
      </CardComponent>

      {addPaymentSourceError && (
        <span className="mt-3 text-center text-red-500">
          {(addPaymentSourceError instanceof HTTPError &&
            addPaymentSourceError.serverMessage) ||
            "Failed to add card. Please try again or contact support"}
        </span>
      )}

      <div className="mt-10 flex justify-between gap-x-4">
        <Button
          onClick={onBack}
          variant="secondary-black"
          disabled={isLoading}
          size="lg"
        >
          Back
        </Button>

        <Button
          isLoading={isLoading}
          onClick={onCreditCardSave}
          size="lg"
          disabled={!nameOnCard.trim()}
        >
          Add card
        </Button>
      </div>
    </>
  );
}
