import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import RenderIf from "common/components/RenderIf";
import Loader from "common/components/Loader";
import { useCampaignContext } from "common/helpers/CampaignContext";

import {
  getSortedAndFilteredFlows,
  isValidSchedule,
  validateFlows,
} from "../../utils";
import Targeting from "./Targeting";
import useTargeting from "../../datahooks/useTargeting";
import useAudienceStats from "../../datahooks/useAudienceStats";
import Audience from "./Audience";
import CampaignSchedule from "./CampaignSchedule";
import Review from "./Review";
import CampaignFlows from "./CampaignFlows";
import AudienceInfo from "./AudienceInfo";
import TargetingPreview from "../TargetingPreview";

export default function CreateCampaign() {
  const { pathname } = useLocation();
  const { targeting, isLoadingTargeting } = useTargeting();
  const { audienceStats, isLoadingAudienceStats } = useAudienceStats();
  const {
    flows,
    inmail_optimization: isInMailOptimizationEnabled,
    schedule: { timezone, ...campaignSchedule },
  } = useCampaignContext();

  const sortedAndFilteredFlows = getSortedAndFilteredFlows(
    flows,
    isInMailOptimizationEnabled,
  );

  const STEPS = [
    {
      path: "targeting",
      component: <Targeting targeting={targeting} />,
    },
    {
      path: "audience",
      component: (
        <Audience
          audienceStats={audienceStats}
          isLoadingAudienceStats={isLoadingAudienceStats}
        />
      ),
    },
    {
      path: "flows",
      component: (
        <CampaignFlows
          audienceStats={audienceStats}
          isLoadingAudienceStats={isLoadingAudienceStats}
        />
      ),
    },
    {
      path: "schedule",
      component: <CampaignSchedule flows={sortedAndFilteredFlows} />,
    },
    {
      path: "review",
      component: <Review flows={sortedAndFilteredFlows} />,
    },
  ];

  const { isFlowListValid } = validateFlows(flows, isInMailOptimizationEnabled);

  if (isLoadingTargeting) return <Loader />;

  const selectedIndex = STEPS.findIndex(({ path }) => pathname.endsWith(path));

  if (selectedIndex === -1) return <Navigate to={STEPS[0].path} replace />;
  if (selectedIndex > 0 && !targeting) {
    return <Navigate to="targeting" replace />;
  }
  if (selectedIndex === 0 && !!targeting) {
    return <Navigate to="audience" replace />;
  }
  if (selectedIndex > 2 && !isFlowListValid) {
    return <Navigate to="flows" replace />;
  }
  if (selectedIndex > 3 && !isValidSchedule(campaignSchedule)) {
    return <Navigate to="schedule" replace />;
  }

  return (
    <div className="mb-32 flex flex-1 flex-col md:mb-28">
      <RenderIf condition={selectedIndex > 0}>
        <TargetingPreview
          isEditDisabled={audienceStats ? audienceStats.progress !== 100 : true}
          shouldShowEditButton
        />
      </RenderIf>
      <RenderIf condition={selectedIndex > 1}>
        <AudienceInfo />
      </RenderIf>
      <Routes>
        {STEPS.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}
      </Routes>
    </div>
  );
}
