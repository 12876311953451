import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { del, patch, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateConversation from "./useUpdateConversation";

async function editMessageRequest(
  workspaceId: string,
  accountId: string,
  conversationUrn: string,
  messageUrn: string,
  message: string,
) {
  await patch(
    `workspaces/${workspaceId}/accounts/${accountId}/conversations/${conversationUrn}/messages/${messageUrn}`,
    { message },
  );
  return { messageUrn, message };
}

async function deleteMessageRequest(
  workspaceId: string,
  accountId: string,
  conversationUrn: string,
  messageUrn: string,
) {
  await del(
    `workspaces/${workspaceId}/accounts/${accountId}/conversations/${conversationUrn}/messages/${messageUrn}`,
  );
  return { messageUrn };
}

async function sendMessageReactionRequest(
  workspaceId: string,
  accountId: string,
  conversationUrn: string,
  messageUrn: string,
  emoji: string,
  unreact: boolean = false,
) {
  await post(
    `workspaces/${workspaceId}/accounts/${accountId}/conversations/${conversationUrn}/messages/${messageUrn}/react`,
    { emoji, unreact },
  );
  return { messageUrn, emoji, unreact };
}

export default function useConversationMessagesUpdates({
  conversationUrn,
}: {
  conversationUrn: string;
}) {
  const { updateConversation, updateConversationMessage } =
    useUpdateConversation();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { mutateAsync: deleteMessage, isPending: isDeletingMessage } =
    useMutation({
      mutationFn: ({
        accountId,
        messageUrn,
      }: {
        accountId: string;
        messageUrn: string;
      }) =>
        deleteMessageRequest(
          workspaceId,
          accountId,
          conversationUrn,
          messageUrn,
        ),
      onSuccess: ({ messageUrn }) => {
        updateConversationMessage(
          conversationUrn,
          messageUrn,
          (draftMessage) => {
            draftMessage.deleted_at = Date.now();
          },
        );
        updateConversation(conversationUrn, (draftConversation) => {
          draftConversation.last_message.deleted_at = Date.now();
        });
      },
    });

  const { mutateAsync: updateMessage, isPending: isUpdatingMessage } =
    useMutation({
      mutationFn: ({
        accountId,
        messageUrn,
        message,
      }: {
        accountId: string;
        messageUrn: string;
        message: string;
      }) =>
        editMessageRequest(
          workspaceId,
          accountId,
          conversationUrn,
          messageUrn,
          message,
        ),
      onSuccess: ({ messageUrn, message: newMessage }) => {
        updateConversationMessage(
          conversationUrn,
          messageUrn,
          (draftMessage) => {
            draftMessage.body = newMessage;
          },
        );
        updateConversation(conversationUrn, (draftConversation) => {
          draftConversation.last_message.body = newMessage;
        });
      },
    });

  const { mutateAsync: sendMessageReaction } = useMutation({
    mutationFn: ({
      accountId,
      messageUrn,
      emoji,
      unreact,
    }: {
      accountId: string;
      messageUrn: string;
      emoji: string;
      unreact: boolean;
    }) =>
      sendMessageReactionRequest(
        workspaceId,
        accountId,
        conversationUrn,
        messageUrn,
        emoji,
        unreact,
      ),
    onMutate: async ({
      messageUrn,
      emoji,
      unreact,
    }: {
      messageUrn: string;
      emoji: string;
      unreact: boolean;
    }) => {
      updateConversationMessage(conversationUrn, messageUrn, (draftMessage) => {
        // Update reactions only, without touching message timestamps
        if (draftMessage.reactions) {
          const reactionObject = draftMessage.reactions.find(
            (reaction) => reaction.emoji === emoji,
          );
          if (unreact) {
            if (reactionObject.count === 1) {
              draftMessage.reactions = draftMessage.reactions.filter(
                (reaction) => reaction.emoji !== emoji,
              );
            } else {
              reactionObject.count -= 1;
              reactionObject.reader_reacted = false;
            }
          } else if (reactionObject) {
            reactionObject.count += 1;
            reactionObject.reader_reacted = true;
          } else {
            draftMessage.reactions.push({
              count: 1,
              reader_reacted: true,
              emoji,
            });
          }
        } else {
          draftMessage.reactions = [
            {
              count: 1,
              reader_reacted: true,
              emoji,
            },
          ];
        }
      });

      // Return context for rollback
      return { messageUrn, emoji, unreact };
    },
    onError: (error, _, context) => {
      // Handle error
      if ("data" in error && error.data === "MaxReactionsError") {
        toast.error(
          "The maximum number of reactions per message has been reached",
        );
      }

      // Rollback on error
      if (context) {
        updateConversationMessage(
          conversationUrn,
          context.messageUrn,
          (draftMessage) => {
            const reactionObject = draftMessage.reactions?.find(
              (reaction) => reaction.emoji === context.emoji,
            );
            if (context.unreact) {
              if (reactionObject) {
                if (reactionObject.count === 1) {
                  draftMessage.reactions = draftMessage.reactions.filter(
                    (reaction) => reaction.emoji !== context.emoji,
                  );
                } else {
                  reactionObject.count += 1;
                  reactionObject.reader_reacted = true;
                }
              }
            } else if (reactionObject) {
              reactionObject.count -= 1;
              reactionObject.reader_reacted = false;
            } else {
              draftMessage.reactions.push({
                count: 1,
                reader_reacted: false,
                emoji: context.emoji,
              });
            }
          },
        );
      }
    },
  });

  return {
    deleteMessage,
    isDeletingMessage,
    updateMessage,
    isUpdatingMessage,
    sendMessageReaction,
  };
}
