import clsx from "clsx";
import CodeMirror from "@uiw/react-codemirror";
import React, { useState, DragEvent, useRef, JSX } from "react";
import { toast } from "react-toastify";
import {
  ArrowCounterClockwise,
  Info,
  UploadSimple,
} from "@phosphor-icons/react";
import { zebraStripes } from "@uiw/codemirror-extensions-zebra-stripes";

import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";

import FolderImage from "assets/images/folder.png";

interface CSVFileUploadProps {
  lineItems: string[];
  setLineItems: (lineItems: string[]) => void;
  errorLines: number[];
  maxAllowedItems: number;
  footerContent?: JSX.Element;
}
export default function CSVFileUpload({
  lineItems,
  setLineItems,
  errorLines,
  footerContent = null,
  maxAllowedItems,
}: CSVFileUploadProps) {
  const inputRef = useRef<HTMLInputElement>();
  const [isDragActive, setIsDragActive] = useState(false);
  function parseFile(file: File) {
    if (!file || file.type !== "text/csv") {
      toast.error(
        "Upload failed. Please make sure you are uploading a CSV file.",
      );
    } else {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result as string;
        const urlsRegexMatch = content.match(/linkedin\.com\/in\/[^\s,]+/g);
        if (urlsRegexMatch) {
          setLineItems(urlsRegexMatch);
        } else {
          toast.error("Error: No LinkedIn URLs were found in your file");
        }
      };
      reader.readAsText(file);
    }
  }
  function onDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragActive(false);
    parseFile(e.dataTransfer.files[0]);
  }

  const validLinksCount = lineItems.length - errorLines.length;

  return (
    <>
      <div
        onDrop={onDrop}
        onDragEnter={() => {
          setIsDragActive(true);
        }}
        onDragLeave={() => {
          setIsDragActive(false);
        }}
        onDragStart={(e) => {
          // eslint-disable-next-line no-param-reassign
          e.dataTransfer.effectAllowed = "all";
          // eslint-disable-next-line no-param-reassign
          e.dataTransfer.dropEffect = "move";
        }}
        onDragOver={(e) => e.preventDefault()}
        className={clsx(
          isDragActive
            ? "border-dashed border-purple-200"
            : "border-transparent",
          "relative flex h-56 flex-col rounded-xl border  bg-whiteGray",
        )}
      >
        {isDragActive ? (
          <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center gap-y-3 rounded-xl bg-purple-50">
            <img alt="" src={FolderImage} />
            <h5 className="text-body-14-bold text-black-700">
              Drop your .CSV file here
            </h5>
          </div>
        ) : (
          <CodeMirror
            style={{ lineHeight: "22px" }}
            value={lineItems.join("\n")}
            className={clsx([
              "overflow-y-auto p-2.5",
              // Editor
              "[&_.cm-scroller]:overflow-y-auto",
              "[&_.cm-editor]:bg-transparent",
              "[&_.cm-content]:bg-transparent",
              "[&_.cm-placeholder]:text-black-400",
              "[&_.cm-focused]:!outline-none",
              "[&_.cm-selectionMatch]:bg-transparent",
              "[&_.cm-line]:flex",
              "[&_.cm-line]:h-7",
              "[&_.cm-line]:items-center",
              "[&_.cm-line]:font-manrope",
              "[&_.cm-line]:text-button-16",
              "[&_.cm-line]:text-black-950",
              "[&_.cm-line]:transition-colors",
              "[&_.cm-line]:selection:!bg-purple-200",
              // Gutter
              "[&_.cm-gutters]:bg-transparent",
              "[&_.cm-gutters]:border-r-0",
              "[&_.cm-gutterElement]:bg-purple-100",
              "[&_.cm-gutterElement]:font-manrope",
              "[&_.cm-gutterElement]:rounded-lg",
              "[&_.cm-gutterElement]:w-[30px]",
              "[&_.cm-gutterElement]:!text-center",
              "[&_.cm-gutterElement]:text-button-12",
              "[&_.cm-gutterElement]:text-purple-600",
              "[&_.cm-gutterElement]:flex",
              "[&_.cm-gutterElement]:items-center",
              "[&_.cm-gutterElement]:shrink-0",
              "[&_.cm-gutterElement]:transition-colors",
              "[&_.cm-gutterElement]:justify-center",
              "[&_.cm-gutterElement]:!p-0",
              "[&_.cm-foldGutter]:!hidden",
              // Active lines
              "[&_.cm-activeLineGutter]:!bg-purple-200",
              "[&_.cm-activeLine]:bg-purple-50",
            ])}
            height="175px"
            onChange={(content) => setLineItems(content.split("\n"))}
            placeholder="Example: https://www.linkedin.com/in/username/"
            extensions={[
              zebraStripes({
                lineNumber: errorLines.length ? errorLines : [0],
                className: "!text-red-500",
              }),
            ]}
            data-cy="csv-editor"
          />
        )}
        <input
          type="file"
          ref={inputRef}
          className="hidden"
          accept="text/csv"
          onChange={(e) => {
            parseFile(e.target.files[0]);
            // by default, if you upload the same file twice the change event will not be called. This resets it
            // eslint-disable-next-line no-param-reassign
            e.target.value = "";
          }}
        />
        <span
          className={clsx(
            "ml-auto mt-auto px-2.5 pb-2.5 text-caption-12-regular",
            validLinksCount > maxAllowedItems
              ? "text-red-400"
              : "text-black-700",
          )}
        >
          {validLinksCount}/{maxAllowedItems} links added
        </span>
      </div>
      <RenderIf condition={validLinksCount > maxAllowedItems}>
        <p className="mt-1 text-caption-12-regular text-red-500">
          You&apos;ve reached the limit of LinkedIn URLs. The maximum limit is
          {maxAllowedItems} URLs
        </p>
      </RenderIf>
      {footerContent || (
        <div className="mt-2 flex items-center justify-between">
          <div className="flex items-center gap-x-0.5">
            <Info className="text-black-700" size={16} weight="fill" />
            <span className="text-caption-12-regular text-black-700">
              You can also easily upload files using drag and drop functionality
            </span>
          </div>
          <Button
            className="ml-auto"
            leftIcon={
              validLinksCount ? <ArrowCounterClockwise /> : <UploadSimple />
            }
            variant={validLinksCount ? "tertiary-black" : "secondary-purple"}
            onClick={
              validLinksCount
                ? () => setLineItems([])
                : () => inputRef.current?.click()
            }
          >
            {validLinksCount ? "Clear all" : "Upload"}
          </Button>
        </div>
      )}
    </>
  );
}
