import React, { useState } from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { Check, PencilSimpleLine, UsersThree, X } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import { Input } from "common/components/ui/Input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import {
  AudienceSizeSchema,
  SearchCampaignAudienceSizeSchema,
} from "common/schemas";
import RenderIf from "common/components/RenderIf";

import useAudienceProgress from "./useAudienceProgress";
import useCampaignMutations from "../../../datahooks/useCampaignMutations";

export default function AudienceHeader({
  isMiningTargets,
}: {
  isMiningTargets: boolean;
}) {
  const [isEditingLimit, setIsEditingLimit] = useState(false);
  const {
    id: campaignId,
    type,
    audience_size: audienceSize,
  } = useCampaignContext();

  const { updateAudienceSize, isUpdatingAudienceSize } = useCampaignMutations();
  const { reconnectSocket } = useAudienceProgress();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { size: audienceSize },
    resolver: valibotResolver(
      type === "search" ? SearchCampaignAudienceSizeSchema : AudienceSizeSchema,
    ),
  });

  function discardEdit() {
    setIsEditingLimit(false);
    reset();
  }

  function handleEditLimit({ size }: { size: number }) {
    updateAudienceSize({
      campaignId,
      size,
    }).then(() => {
      reconnectSocket();
      reset({ size });
      setIsEditingLimit(false);
    });
  }

  let editButton = (
    <Button
      intent="iconOnly"
      variant="secondary-black"
      size="sm"
      onClick={() => setIsEditingLimit(true)}
      disabled={isMiningTargets}
    >
      <PencilSimpleLine />
    </Button>
  );

  if (isMiningTargets) {
    editButton = (
      <Tooltip>
        <TooltipTrigger asChild>{editButton}</TooltipTrigger>
        <TooltipContent align="end">
          Cannot edit number of targets while mining is in progress
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <article className="mb-4 flex items-center gap-4">
      <div>
        <h5 className="mb-1 text-headline-xl-bold">Audience preview</h5>
        <p className="text-black-500">
          A detailed look at your audience for this campaign
        </p>
      </div>

      <RenderIf condition={type !== "list"}>
        <div
          className={clsx(
            "ml-auto flex items-center gap-2",
            isEditingLimit && "max-md:flex-col",
          )}
        >
          {isEditingLimit ? (
            <>
              <Input
                variant="sm"
                disabled={isUpdatingAudienceSize}
                leftComponent={<UsersThree weight="fill" />}
                className="max-w-24 shrink-0"
                error={errors.size?.message}
                {...register("size", {
                  valueAsNumber: true,
                })}
              />
              <div className="flex gap-2">
                <Button
                  intent="iconOnly"
                  variant="tertiary-black"
                  size="sm"
                  onClick={discardEdit}
                  disabled={isUpdatingAudienceSize}
                >
                  <X />
                </Button>
                <Button
                  intent="iconOnly"
                  variant="secondary-purple"
                  size="sm"
                  onClick={handleSubmit(handleEditLimit)}
                  isLoading={isUpdatingAudienceSize}
                >
                  <Check />
                </Button>
              </div>
            </>
          ) : (
            <>
              <Tag
                size="lg"
                leftIcon={<UsersThree weight="fill" />}
                variant="info"
              >
                {audienceSize}
              </Tag>
              {editButton}
            </>
          )}
        </div>
      </RenderIf>
    </article>
  );
}
