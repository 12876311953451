import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { post } from "common/helpers/HTTP";

async function getTokenRequest(
  workspaceId: string,
  accountId: string,
): Promise<string> {
  return (
    await post(
      `workspaces/${workspaceId}/token`,
      accountId && { account_id: accountId },
    )
  ).token;
}

export default function useWorkspaceToken({
  accountId,
}: {
  accountId?: string;
} = {}) {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: workspaceToken, isLoading: isLoadingWorkspaceToken } = useQuery(
    {
      queryKey: [workspaceId, "token"],
      queryFn: () => getTokenRequest(workspaceId, accountId),
    },
  );

  return { workspaceToken, isLoadingWorkspaceToken };
}
