import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { AudienceStats } from "../types";

async function getAudienceStatsRequest(
  workspaceId: string,
  campaignId: string,
): Promise<AudienceStats> {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/audience-stats`,
    )
  ).stats;
}

export default function useAudienceStats({
  disableQuery,
}: {
  disableQuery?: boolean;
} = {}) {
  const { id: campaignId } = useCampaignContext();

  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: audienceStats, isLoading: isLoadingAudienceStats } = useQuery({
    queryKey: campaignsKeys.audienceStats(campaignId),
    queryFn: () => getAudienceStatsRequest(workspaceId, campaignId),
    enabled: !disableQuery,
  });

  return {
    audienceStats,
    isLoadingAudienceStats,
  };
}
