import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CircleNotch } from "@phosphor-icons/react";

import useBraintreeToken from "common/datahooks/useBraintreeToken";

const paypalSpinnerId = "paypal-spinner";

export default function PaypalCheckout({
  onTokenReceived,
}: {
  onTokenReceived: (token: string) => void;
}) {
  const [isError, setIsError] = useState(false);
  const [braintreeClient, setBraintreeClient] = useState<BraintreeClient>(null);

  const { braintreeToken } = useBraintreeToken();

  useEffect(() => {
    if (braintreeToken) {
      braintree.client
        .create({
          authorization: braintreeToken,
        })
        .then((clientInstance) => {
          setBraintreeClient(clientInstance);
        });
    }
  }, [braintreeToken]);

  useEffect(() => {
    if (braintreeClient) {
      braintree.paypalCheckout
        .create({
          client: braintreeClient,
        })
        .then((paypalCheckoutInstance) =>
          paypalCheckoutInstance.loadPayPalSDK({
            vault: true,
          }),
        )
        .then((paypalCheckoutInstance) => {
          document.getElementById(paypalSpinnerId)?.remove();
          paypal
            .Buttons({
              fundingSource: paypal.FUNDING.PAYPAL as unknown as string,
              createBillingAgreement() {
                return paypalCheckoutInstance.createPayment({
                  flow: "vault",
                  enableShippingAddress: false,
                });
              },
              async onApprove(data: unknown) {
                const payload =
                  await paypalCheckoutInstance.tokenizePayment(data);
                onTokenReceived(payload.nonce);
                return payload;
              },
              onCancel() {},
              onError() {
                toast.error("PayPal payment error");
                // console.error("PayPal error", err);
              },
            })
            .render("#paypal-button");
        })
        .then(() => {
          // The PayPal button will be rendered in an html element with the ID
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, [braintreeClient]);

  if (isError) {
    return (
      <span className="mb-2.5 text-center text-red-500">
        Error creating paypal component
      </span>
    );
  }

  return (
    <div
      className="flex h-[52px] w-full items-center justify-center overflow-hidden rounded-2xl bg-[#ffc439] hover:bg-[#E7BE41]"
      id="paypal-button"
    >
      <CircleNotch
        id={paypalSpinnerId}
        size={20}
        className="animate-spin fill-[#003087]"
      />
    </div>
  );
}
