import React from "react";

import Skeleton from "../ui/Skeleton";

export default function ExtensionLoginLoader() {
  return (
    <div className="flex flex-row items-center gap-x-2 rounded-20 border border-black-200 p-4">
      <Skeleton className="size-10 rounded-full" />

      <div className="flex flex-col gap-y-1">
        <Skeleton className="h-4 w-36" />
        <Skeleton className="h-4 w-56" />
      </div>
    </div>
  );
}
