import {
  email,
  maxLength,
  minLength,
  object,
  optional,
  string,
  trim,
  regex,
  InferOutput,
  pipe,
  intersect,
  picklist,
  number,
  minValue,
  maxValue,
  nonEmpty,
  integer,
} from "valibot";

import { LabelColor } from "common/types";

const EmailPipeline = pipe(
  string(),
  trim(),
  minLength(1, "Email is required"),
  maxLength(254, "Email must be 254 characters or less"),
  email("Invalid email address format"),
);

export const EmailSchema = object({
  email: EmailPipeline,
});

export const FullNameSchema = object({
  firstName: pipe(
    string(),
    trim(),
    minLength(1, "First name is required"),
    maxLength(40, "First name can't be more than 40 characters."),
  ),
  lastName: pipe(
    string(),
    trim(),
    minLength(1, "Last name is required"),
    maxLength(40, "Last name can't be more than 40 characters."),
  ),
});
export const LoginSchema = intersect([
  EmailSchema,
  object({
    password: pipe(string(), minLength(1, "Password is required")),
    proxyLocation: pipe(string(), minLength(1, "Proxy is required")),
  }),
]);
export type LoginSchemaType = InferOutput<typeof LoginSchema>;

export const NoteSchema = object({
  text: pipe(string(), trim(), minLength(1, "Text is required")),
});

export type NoteSchemaType = InferOutput<typeof NoteSchema>;
export const LinkedinPostSchema = object({
  postUrl: pipe(
    string(),
    regex(
      /^https:\/\/www\.linkedin\.com\/(posts\/[a-zA-Z0-9%_-]+|feed\/update\/urn:li:activity:\d+(\/\S*)?|in\/\S+\/\S+(-\S+)?)(\?.*)?$/,
      "Please enter a valid LinkedIn post URL.",
    ),
  ),
});

const BaseTemplateSchema = object({
  id: optional(string()),
  type: pipe(string(), trim()),
  name: pipe(
    string(),
    trim(),
    minLength(1, "Name is required"),
    maxLength(50, "Name can't be more than 50 characters long"),
  ),
});

export const FlowNoteTemplateSchema = object({
  message: pipe(
    string(),
    trim(),
    minLength(1, "Message is required"),
    maxLength(250, "Message can't be more than 250 characters long"),
  ),
});

export const FlowInmailTemplateSchema = object({
  message: pipe(
    string(),
    trim(),
    minLength(1, "Message is required"),
    maxLength(1900, "Message can't be more than 1900 characters long"),
  ),
  subject: pipe(
    string(),
    trim(),
    minLength(1, "Subject is required for InMail templates"),
    maxLength(200, "Subject can't be more than 200 characters long"),
  ),
});

export const FlowMessageTemplateSchema = object({
  message: pipe(
    string(),
    trim(),
    minLength(1, "Message is required"),
    maxLength(1900, "Message can't be more than 1900 characters long"),
  ),
});

export const NoteTemplateSchema = object({
  ...BaseTemplateSchema.entries,
  ...FlowNoteTemplateSchema.entries,
});

export const InmailTemplateSchema = object({
  ...BaseTemplateSchema.entries,
  ...FlowInmailTemplateSchema.entries,
});

export const MessageTemplateSchema = object({
  ...BaseTemplateSchema.entries,
  ...FlowMessageTemplateSchema.entries,
});

export const CampaignNameSchema = object({
  name: pipe(
    string(),
    trim(),
    minLength(1, "Name is required"),
    maxLength(50, "Campaign name can't be more than 50 characters long"),
  ),
});

export const AudienceSizeSchema = object({
  size: pipe(
    number("Invalid value"),
    minValue(1, "Limit is required"),
    maxValue(10000, "Limit is 10000"),
  ),
});

export const SearchCampaignAudienceSizeSchema = object({
  size: pipe(
    number("Invalid value"),
    minValue(1, "Limit is required"),
    maxValue(1000, "Limit is 1000"),
  ),
});

export const DefaultCampaignSettingsSchema = object({
  ...CampaignNameSchema.entries,
  ...AudienceSizeSchema.entries,
});

export const SearchCampaignSettingsSchema = object({
  ...CampaignNameSchema.entries,
  ...SearchCampaignAudienceSizeSchema.entries,
});

export const WorkspaceNameSchema = object({
  name: pipe(
    string(),
    trim(),
    minLength(1, "Workspace name is required"),
    maxLength(30, "Workspace name can't be more than 30 characters long"),
  ),
});

export const LabelSchema = object({
  name: pipe(
    string(),
    trim(),
    nonEmpty("Label name is required"),
    maxLength(255, "Label name can't be more than 255 characters long"),
  ),
  color: picklist<LabelColor[], string>(
    ["yellow", "quaternary", "info", "secondary", "success", "danger"],
    "Invalid Color",
  ),
});
export type LabelSchemaType = InferOutput<typeof LabelSchema>;

export const CouponSchema = object({
  coupon: pipe(string(), trim(), minLength(1, "Coupon is required")),
});

export const QuestionnaireCustomInputSchema = object({
  customInput: pipe(string(), trim(), minLength(1, "Text is required")),
});

export const AssignSeatsSchema = object({
  seats: pipe(
    number("Seats to assign must be a number"),
    minValue(1, "Seats to assign must be greater than zero"),
    integer(),
  ),
});
export type AssignSeatsSchemaType = InferOutput<typeof AssignSeatsSchema>;
