import React from "react";

import TemplateDetailsForm from "common/components/TemplateDetailsForm";
import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { CreatedTemplate } from "common/types";

import useTemplate from "../../datahooks/useTemplate";

interface TemplateDialogProps {
  template: CreatedTemplate;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: "edit" | "delete";
}

export default function TemplateDialog({
  isOpen,
  setIsOpen,
  template,
  action,
}: TemplateDialogProps) {
  const { deleteTemplate, isDeletingTemplate } = useTemplate();

  function removeTemplate() {
    deleteTemplate({ templateId: template.id }).then(() => setIsOpen(false));
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className={action === "delete" && "md:w-[448px]"}>
        {action === "delete" ? (
          <>
            <h5 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
              Delete template
            </h5>
            <p className="mb-12 text-center text-black-500">
              Are you sure you want to delete &quot;{template.name}
              &quot; Template?
            </p>

            <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
              <Button
                data-cy="delete-template-button"
                variant="tertiary-danger"
                className="flex-1"
                onClick={removeTemplate}
                isLoading={isDeletingTemplate}
              >
                Yes
              </Button>
              <Button
                variant="secondary-black"
                className="flex-1"
                onClick={() => setIsOpen(false)}
                disabled={isDeletingTemplate}
              >
                Back
              </Button>
            </div>
          </>
        ) : (
          <TemplateDetailsForm
            template={template}
            goBack={() => setIsOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
