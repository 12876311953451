import { useMutation } from "@tanstack/react-query";

import { patch } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateCampaign from "./useUpdateCampaign";
import { CampaignSchedule, DetailedCampaign } from "../types";

async function setScheduleRequest(
  workspaceId: string,
  campaignId: string,
  schedule: CampaignSchedule,
): Promise<DetailedCampaign> {
  return (
    await patch(`workspaces/${workspaceId}/campaigns/${campaignId}/schedule`, {
      ...schedule,
    })
  ).campaign;
}

export default function useCampaignSchedule() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { id: campaignId } = useCampaignContext();

  const { updateCachedCampaign } = useUpdateCampaign();

  const {
    mutateAsync: setCampaignSchedule,
    isPending: isSettingCampaignSchedule,
  } = useMutation({
    mutationFn: ({ schedule }: { schedule: CampaignSchedule }) =>
      setScheduleRequest(workspaceId, campaignId, schedule),
    onSuccess: ({ schedule }) => {
      updateCachedCampaign(campaignId, (draftCampaign) => {
        draftCampaign.schedule = schedule;
      });
    },
  });

  return { setCampaignSchedule, isSettingCampaignSchedule };
}
