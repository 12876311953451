import React, { useEffect, useState } from "react";
import { CreditCard as CardIcon } from "@phosphor-icons/react";

import useGTM from "common/hooks/useGTM";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { Button } from "common/components/ui/Button";
import usePaymentSources from "common/datahooks/usePaymentSources";
import { PaymentSource } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import CreditCard from "./CreditCard";
import PaypalCheckout from "./PaypalCheckout";

interface AddPaymentMethodProps {
  onBack: () => void;
  onNext?: (newPaymentSource: PaymentSource) => void;
}

export default function AddPaymentMethod({
  onBack,
  onNext = null,
}: AddPaymentMethodProps) {
  const [isAddingCard, setIsAddingCard] = useState(false);
  const { subscription } = useSelectedWorkspaceContext();
  const { setPrimaryPaymentSource, isSettingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  const { addPaymentSource, isAddingPaymentSource } = usePaymentSources();

  const { sendEvent } = useGTM();

  useEffect(() => {
    let isCbInitialized: boolean;
    try {
      isCbInitialized = !!window.Chargebee.getInstance();
    } catch (err) {
      isCbInitialized = false;
    }

    if (window.Chargebee && !isCbInitialized) {
      window.Chargebee.init({
        site: import.meta.env.VITE_CHARGEBEE_SITE,
        publishableKey: import.meta.env.VITE_CHARGEBEE_KEY,
      });
    }
  }, []);

  function onAdd(token: string, source: "card" | "paypal" | "apple-pay") {
    addPaymentSource({ token, source })
      .then((paymentSource) => {
        sendEvent("add_payment_source_success", ({ email }) => ({
          email,
          payment_method: source,
        }));

        if (subscription) {
          setPrimaryPaymentSource({ paymentSource }).then(() => onBack());
          return;
        }

        if (onNext) {
          onNext(paymentSource);
        }
      })
      .catch(() => {
        sendEvent("add_payment_source_fail", ({ email }) => ({
          email,
          payment_method: source,
        }));
      });
  }

  const isLoading = isAddingPaymentSource || isSettingPrimaryPaymentSource;

  let content = (
    <>
      <div className="flex flex-col gap-4">
        <button
          className="flex w-full items-center justify-center gap-2 rounded-2xl bg-purple-50 py-4 text-body-14-bold"
          onClick={() => setIsAddingCard(true)}
          type="button"
        >
          <CardIcon weight="fill" size={20} />
          Credit Card
        </button>

        <PaypalCheckout onTokenReceived={(token) => onAdd(token, "paypal")} />
      </div>

      <Button
        onClick={onBack}
        variant="secondary-black"
        disabled={isLoading}
        size="lg"
        className="mr-auto mt-10"
      >
        Back
      </Button>
    </>
  );

  if (isAddingCard) {
    content = (
      <CreditCard
        onTokenReceived={(token) => onAdd(token, "card")}
        onBack={() => setIsAddingCard(false)}
      />
    );
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Add payment method
      </h3>

      <p className="mb-8 text-center text-black-500">
        Add a preferred payment method for your Aimfox subscription. You can
        change this at any time
      </p>

      {content}
    </>
  );
}
