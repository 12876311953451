import React, { JSX } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { clsx } from "clsx";
import { SealCheck } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import ProfileImage, {
  type ProfileImageProps,
} from "common/components/ui/ProfileImage";

const variants = cva(
  "flex items-center gap-x-1 rounded-full py-0.5 pl-1 pr-2",
  {
    variants: {
      size: {
        sm: "gap-x-1 text-caption-10-semibold",
        md: "gap-x-1 text-button-12",
        lg: "gap-x-1.5 text-button-12",
      },
      variant: {
        purple: "bg-purple-50",
        gray: "bg-black-100 text-black-400",
        disabled: "bg-black-200 text-black-400 [&_img]:grayscale",
      },
    },
  },
);

interface LinkedInAccountsLabelProps
  extends Pick<VariantProps<typeof variants>, "size"> {
  accountIds: string[];
  rightComponent?: JSX.Element;
  isDisabled?: boolean; // This is only used for disabled MessageField in Inbox (single account)
}

const profileImageSizes: Record<
  LinkedInAccountsLabelProps["size"],
  ProfileImageProps["size"]
> = {
  sm: "3xs",
  md: "2xs",
  lg: "xs",
};

export default function LinkedInAccountsTag({
  accountIds,
  size = "md",
  rightComponent = null,
  isDisabled = false,
}: LinkedInAccountsLabelProps) {
  const { accounts } = useSelectedWorkspaceContext();

  if (accountIds.length === 1) {
    const {
      picture_url: pictureUrl,
      full_name: fullName,
      license,
    } = accounts.find(({ id }) => id === accountIds[0]) || {};

    let variant: VariantProps<typeof variants>["variant"];
    if (isDisabled) {
      variant = "disabled";
    } else {
      variant = license ? "purple" : "gray";
    }

    return (
      <div
        className={clsx(
          variants({
            size,
            variant,
          }),
        )}
      >
        <ProfileImage
          size={profileImageSizes[size]}
          className="rounded-full"
          src={pictureUrl}
        />

        <span className="truncate">{fullName}</span>

        <Tooltip>
          <TooltipTrigger asChild>
            <div tabIndex={0} role="button" aria-label="License info">
              <SealCheck
                weight="fill"
                className={clsx(license && !isDisabled && "fill-purple-600")}
                size={16}
              />
            </div>
          </TooltipTrigger>

          <TooltipContent className="w-fit max-w-56">
            {fullName} ({license ? "" : "No "}License)
          </TooltipContent>
        </Tooltip>

        {rightComponent}
      </div>
    );
  }

  const filteredAccounts = accounts.filter(({ id }) => accountIds.includes(id));
  const isAnyLicenseAvailable = filteredAccounts.some(
    ({ license }) => !!license,
  );
  return (
    <div
      className={clsx(
        variants({ size, variant: isAnyLicenseAvailable ? "purple" : "gray" }),
      )}
    >
      <div className="flex shrink-0 flex-row -space-x-1.5 rtl:space-x-reverse">
        {filteredAccounts.slice(0, 3).map(({ id, picture_url: pictureUrl }) => (
          <ProfileImage
            key={id}
            size={profileImageSizes[size]}
            className={clsx(
              "rounded-full ring-1",
              isAnyLicenseAvailable ? "ring-purple-50" : "ring-black-100",
            )}
            src={pictureUrl}
          />
        ))}
      </div>

      <Tooltip>
        <TooltipTrigger>{filteredAccounts.length} accounts</TooltipTrigger>

        <TooltipContent className="flex w-fit max-w-56 flex-col">
          {filteredAccounts.map(({ full_name: fullName, license, id }) => (
            <span key={id}>
              {fullName} ({license ? "" : "No "}License)
            </span>
          ))}
        </TooltipContent>
      </Tooltip>

      {rightComponent}
    </div>
  );
}
