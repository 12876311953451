import React from "react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogClose, DialogFooter } from "common/components/ui/Dialog";
import { DrawerClose, DrawerFooter } from "common/components/ui/Drawer";

import SuccessImage from "assets/images/success.png";
import FreeTrialSuccessImage from "assets/images/free-trial-success.png";

export default function Success({
  isFreeTrial = false,
}: {
  isFreeTrial?: boolean;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  return (
    <>
      <img
        src={isFreeTrial ? FreeTrialSuccessImage : SuccessImage}
        width={364}
        height={254}
        className="mx-auto mb-4"
        alt="Success illustration"
      />
      <h3 className="mb-2 text-center text-headline-2xl-bold">
        {isFreeTrial
          ? "Your 7-day Trial is active!"
          : "Your subscription has been updated!"}
      </h3>
      <p className="text-center text-black-500 md:mb-8">
        {isFreeTrial
          ? "Enjoy unlimited campaigns with unlimited accounts and the full power of LinkedIn Outreach"
          : "Subscription updated successfully. You can always review your current subscription in your Workspace settings"}
      </p>

      <Footer className="shadow-none border-none md:-mx-16">
        <Close asChild>
          <Button
            className="max-md:w-full md:ml-auto"
            variant="secondary-black"
            size="lg"
          >
            Continue
          </Button>
        </Close>
      </Footer>
    </>
  );
}
