import React from "react";
import {
  DotsThreeVertical,
  PencilSimpleLine,
  Trash,
} from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";

export default function TemplateActions({
  openActionModal,
}: {
  openActionModal: (action: "edit" | "delete") => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger data-cy="template-actions-dropdown" asChild>
        <Button
          intent="iconOnly"
          variant="secondary-black"
          onClick={(e) => e.stopPropagation()}
        >
          <DotsThreeVertical />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
        <DropdownMenuItem
          data-cy="edit-template-menu-item"
          onClick={() => openActionModal("edit")}
        >
          <PencilSimpleLine size={20} />
          <span>Edit</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          data-cy="delete-template-menu-item"
          className="text-red-500"
          onClick={() => openActionModal("delete")}
        >
          <Trash size={20} />
          <span>Delete template</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
