import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

async function testWebhookRequest(
  workspaceId: string,
  webhookId: string,
  event: string,
): Promise<{
  request: { headers: object; body: object };
  response: { headers: object; body: object; status: number | "ENOTFOUND" };
}> {
  return (
    await post(
      `workspaces/${workspaceId}/webhooks/${webhookId}/events/${event}/test`,
    )
  ).data;
}

export default function useWebhookEventsMutations() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const {
    mutateAsync: testWebhook,
    isPending: isTestingWebhook,
    data: testWebhookResult,
  } = useMutation({
    mutationFn: ({ webhookId, event }: { webhookId: string; event: string }) =>
      testWebhookRequest(workspaceId, webhookId, event),
    onError: () => toast.error("Error testing Webhook"),
  });

  return {
    testWebhook,
    isTestingWebhook,
    testWebhookResult,
  };
}
