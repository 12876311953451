import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

async function getUnreadTokenRequest(workspaceId: string): Promise<string> {
  return (await get(`workspaces/${workspaceId}/unread-token`)).token;
}

export default function useUnreadToken() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: unreadToken, isPending: isLoadingToken } = useQuery({
    queryKey: ["unreadToken", workspaceId],
    queryFn: () => getUnreadTokenRequest(workspaceId),
    staleTime: Infinity,
  });

  return { unreadToken, isLoadingToken };
}
