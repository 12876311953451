import React from "react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { Webhook } from "../../types";
import useWebhookMutations from "../../datahooks/useWebhookMutations";

interface DeleteWebhookProps {
  webhook: Webhook;
  onBack: () => void;
  onSuccess: () => void;
}

export default function DeleteWebhook({
  webhook: { id, name },
  onBack,
  onSuccess,
}: DeleteWebhookProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { deleteWebhook, isDeletingWebhook } = useWebhookMutations();

  function onDelete() {
    deleteWebhook({ webhookId: id }).then(() => onSuccess());
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-xl-bold">Delete webhook</h3>
      <p className="mb-8 text-pretty text-center text-black-500">
        Are you sure you want to delete{" "}
        <span className="break-words text-body-14-bold text-black-950">
          {name}
        </span>
        ?
      </p>
      <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          variant="tertiary-danger"
          isLoading={isDeletingWebhook}
          className="flex-1"
          onClick={onDelete}
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Delete
        </Button>
        <Button
          variant="secondary-black"
          onClick={onBack}
          disabled={isDeletingWebhook}
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
