/* eslint-disable */
// @ts-nocheck

import { isProductionEnvironment } from "common/helpers/utils";

export default function useCustomerIO() {
  function initializeCustomerIO(id: string, email: string) {
    if (isProductionEnvironment()) {
      !(function () {
        const analytics = (window.analytics = window.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error("Snippet included twice.");
          else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (e) {
              return function () {
                const t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (let e = 0; e < analytics.methods.length; e++) {
              const key = analytics.methods[e];
              analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key, e) {
              const t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src =
                "https://cdp.customer.io/v1/analytics-js/snippet/" +
                key +
                "/analytics.min.js";
              const n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._writeKey = key;
              analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load(
              import.meta.env.VITE_CUSTOMERIO_API_KEY,
              // Optional: support in-app messaging
              {
                integrations: {
                  "Customer.io In-App Plugin": {
                    siteId: import.meta.env.VITE_CUSTOMERIO_SITE_ID,
                  },
                },
              },
            );
            analytics.page();
          }
      })();
      window.analytics.identify(id, {
        email,
      });
    }
  }
  return { initializeCustomerIO };
}
