import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useCampaignContext } from "common/helpers/CampaignContext";

import Schedule from "../../Schedule";
import { formattedTimezone } from "../../../utils";
import ScheduleEdit from "./ScheduleEdit";

export default function CampaignSchedule() {
  const { schedule: campaignSchedule } = useCampaignContext();
  const { timezone, ...initialSchedule } = campaignSchedule;
  const [schedule, setSchedule] = useState(initialSchedule);
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(
    formattedTimezone(timezone),
  );

  const { t } = useTranslation("campaigns");

  function resetSchedule() {
    setIsEditingSchedule(false);
    setSchedule(initialSchedule);
  }

  return (
    <>
      <section className="mb-4 flex justify-between gap-4">
        <div>
          <h2 className="text-headline-xl-bold">{t("scheduleTitle")}</h2>
          <p className="text-black-500">{t("scheduleDescription")}</p>
        </div>
        <ScheduleEdit
          isEditing={isEditingSchedule}
          setIsEditing={setIsEditingSchedule}
          resetSchedule={resetSchedule}
          selectedTimezone={selectedTimezone}
          schedule={schedule}
        />
      </section>

      <Schedule
        schedule={schedule}
        timezone={selectedTimezone}
        setSchedule={setSchedule}
        setSelectedTimezone={setSelectedTimezone}
        isDisabled={!isEditingSchedule}
      />
    </>
  );
}
