import React, { useState } from "react";
import clsx from "clsx";
import { CaretDown, Link as LinkIcon, PlusCircle } from "@phosphor-icons/react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { LinkedInLogin } from "common/components/LinkedInLogin";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import WhiteLabelUrl from "./WhiteLabelUrl";

export default function AddAccountButton() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<"whiteLabel" | "login">(null);

  function closeModal() {
    setIsOpen(false);
  }

  const sharedStyles =
    "flex h-10 cursor-pointer items-center justify-center bg-black-950 text-button-14 transition-colors duration-300 ease-in-out hover:bg-black-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-black-200 disabled:text-black-400";

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <>
      <div className="group flex items-center overflow-hidden rounded-full text-white">
        <button
          type="button"
          onClick={() => {
            setAction("login");
            setIsOpen(true);
          }}
          className={clsx(sharedStyles, "w-full gap-x-2 pl-3 pr-2")}
          data-cy="add-account-button"
        >
          <PlusCircle weight="fill" size={20} /> Add account
        </button>

        <DropdownMenu>
          <DropdownMenuTrigger
            className={clsx(
              sharedStyles,
              "group relative pl-2 pr-3 data-[state=open]:bg-black-700",
              // Separator
              "before:absolute before:left-0 before:h-5 before:w-px before:bg-black-700 before:opacity-100 before:transition-opacity before:duration-300 before:ease-in-out before:group-hover:opacity-0",
            )}
          >
            <CaretDown
              size={20}
              className="transition-transform group-data-[state=open]:rotate-180"
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                setAction("whiteLabel");
                setIsOpen(true);
              }}
            >
              <LinkIcon size={20} /> <span>Connect via link</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <Component open={isOpen} onOpenChange={setIsOpen}>
        <Content>
          {action === "login" ? (
            <LinkedInLogin onSuccess={closeModal} />
          ) : (
            <WhiteLabelUrl goBack={closeModal} />
          )}
        </Content>
      </Component>
    </>
  );
}
