import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useTemplates from "common/datahooks/useTemplates";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import SearchInput from "common/components/SearchInput";
import useAppStore from "common/hooks/useAppStore";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import TemplatesTable from "./TemplatesTable";
import TemplateTypeFilter from "./TemplateTypeFilter";
import CreateTemplate from "./CreateTemplate";
import useTemplatesTable from "../../hooks/useTemplatesTable";

import NoResultsImage from "assets/images/empty-placeholders/no-templates.png";
import EmptyPlaceholderImage from "assets/images/empty-placeholders/new-template.png";

export default function TemplatesList() {
  const isDesktop = useTwBreakpoint("lg");
  const { t } = useTranslation("templates");

  const {
    allTemplates,
    isLoadingAllTemplates,
    templatesError,
    refetchTemplates,
  } = useTemplates();

  const {
    filteredTemplates,
    searchText,
    templateType,
    setTemplateType,
    setSearchText,
    hasFilters,
    clearFilters,
  } = useTemplatesTable(allTemplates);

  useEffect(() => {
    if (!isDesktop) {
      useAppStore
        .getState()
        .setHeaderContent({ actionComponent: <CreateTemplate /> });
      return () => useAppStore.getState().setHeaderContent(null);
    }
  }, [isDesktop]);

  if (templatesError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Templates"
        onRetry={refetchTemplates}
      />
    );
  }

  if (!isLoadingAllTemplates && (!allTemplates || !allTemplates.length)) {
    return (
      <EmptyPlaceholder
        title={t("emptyTemplatesTitle")}
        subtitle={t("emptyTemplatesSubtitle")}
        imageSrc={EmptyPlaceholderImage}
        actionComponent={<CreateTemplate />}
      />
    );
  }

  const currentPageTemplates = filteredTemplates.length
    ? filteredTemplates
    : new Array(7).fill({});

  return (
    <>
      {/* Top Section */}
      <div className="flex w-full items-center gap-4">
        <SearchInput
          className="max-lg:flex-1 lg:w-80"
          placeholder="Search template"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onClear={() => setSearchText("")}
        />

        <TemplateTypeFilter
          selectedFilter={templateType}
          setFilter={setTemplateType}
          templates={allTemplates}
        />

        {isDesktop && <CreateTemplate className="ml-auto" />}
      </div>

      {hasFilters && !filteredTemplates.length ? (
        <EmptyPlaceholder
          title="No results found"
          subtitle="There were no results found for your search. Try searching for something else"
          imageSrc={NoResultsImage}
          actionComponent={<Button onClick={clearFilters}>Clear Search</Button>}
        />
      ) : (
        <TemplatesTable
          templates={currentPageTemplates}
          isLoading={isLoadingAllTemplates}
        />
      )}
    </>
  );
}
