import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "common/components/ui/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useTargeting from "../datahooks/useTargeting";

export default function ResetTargeting({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation("campaigns");
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { removeTargeting, isRemovingTargeting } = useTargeting();

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content className="md:w-[448px]">
        <h5 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          {t("targetingPreviewTitle")}
        </h5>
        <p className="px-2 text-center text-black-500">
          {t("targetingPreviewSubtitle")}
        </p>
        <div className="mt-8 flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
          <Button
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
            onClick={() => removeTargeting()}
            isLoading={isRemovingTargeting}
          >
            Yes
          </Button>
          <Close asChild>
            <Button
              variant="tertiary-black"
              className="flex-1"
              size={isTabletOrDesktop ? "md" : "lg"}
            >
              No
            </Button>
          </Close>
        </div>
      </Content>
    </Component>
  );
}
