import React from "react";
import { CaretDown } from "@phosphor-icons/react";
import { clsx } from "clsx";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "common/components/ui/DropdownMenu";
import Checkbox from "common/components/ui/Checkbox";
import useCampaigns from "common/datahooks/useCampaigns";

import Skeleton from "../../../../common/components/ui/Skeleton";

interface CampaignSelectorProps {
  selectedCampaignIds: string[];
  setSelectedCampaignIds: (selectedCampaignIds: string[]) => void;
  className?: string;
}

export default function CampaignSelector({
  selectedCampaignIds,
  setSelectedCampaignIds,
  className = "",
}: CampaignSelectorProps) {
  const { campaigns, isLoadingCampaigns } = useCampaigns();

  const activeCampaigns = campaigns?.filter(
    (campaign) => campaign.state !== "INIT" && campaign.state !== "CREATED",
  );

  const hasCampaigns = activeCampaigns && activeCampaigns.length > 0;

  function removeCampaignId(campaignId: string): void {
    setSelectedCampaignIds(
      selectedCampaignIds.filter((id) => id !== campaignId),
    );
  }

  function addCampaignId(campaignId: string): void {
    setSelectedCampaignIds([...selectedCampaignIds, campaignId]);
  }

  let triggerLabel = "All campaigns";

  if (hasCampaigns && selectedCampaignIds.length > 0) {
    triggerLabel =
      selectedCampaignIds.length > 1
        ? `${selectedCampaignIds.length} campaigns selected`
        : activeCampaigns.find(({ id }) => id === selectedCampaignIds[0])?.name;
  }

  if (isLoadingCampaigns) {
    return <Skeleton className="h-10 w-full rounded-full" />;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={!hasCampaigns}>
        <Button
          className={clsx("group", className)}
          variant="tertiary-black"
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
        >
          <span className="mr-auto truncate">
            {hasCampaigns ? triggerLabel : "No campaigns available"}
          </span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="z-aboveDialog max-h-[325px] w-full min-w-[--radix-dropdown-menu-trigger-width] max-w-[406px] overflow-y-auto scrollbar-thin md:max-h-[250px] md:max-w-[508px]"
        align="end"
      >
        <DropdownMenuItem
          className="gap-x-1"
          onSelect={() => {
            setSelectedCampaignIds([]);
          }}
        >
          All campaigns
        </DropdownMenuItem>

        {activeCampaigns.map(({ id, name }) => {
          const isCampaignIdIncluded = selectedCampaignIds.includes(id);

          return (
            <DropdownMenuItem
              key={id}
              className="gap-x-1"
              onSelect={() => {
                setSelectedCampaignIds([id]);
              }}
            >
              <Checkbox
                checked={isCampaignIdIncluded}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => {
                  if (isCampaignIdIncluded) {
                    removeCampaignId(id);
                    return;
                  }

                  addCampaignId(id);
                }}
                size="sm"
              />

              <span className="ml-1 truncate">{name}</span>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
