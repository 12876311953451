import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";

import Checkbox from "common/components/ui/Checkbox";
import SearchInput from "common/components/SearchInput";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";

import { WebhookEvent } from "../../types";

type WebhookEventsSelectProps = {
  events: string[] | WebhookEvent[];
  isLoading?: boolean;
} & (
  | {
      isMultiSelect: false;
      selection: string;
      setSelection: (newEvent: string) => void;
    }
  | {
      isMultiSelect: true;
      selection: string[];
      setSelection: (newEvents: string[]) => void;
    }
);

export default function WebhookEventsSelect({
  events,
  selection,
  setSelection,
  isMultiSelect,
  isLoading = false,
}: WebhookEventsSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const triggerText = isMultiSelect === true ? selection.join(", ") : selection;

  const filteredEvents = events
    ? events.filter((event) => {
        const eventType = typeof event === "string" ? event : event.type;
        return eventType.includes(inputValue.trim().toLowerCase());
      })
    : [];

  return (
    <Popover modal open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className={clsx(
          "group flex h-12 w-full shrink-0 items-center justify-between gap-x-4 rounded-xl bg-whiteGray px-4 text-left text-button-16",
          (!selection || !selection?.length) && "text-black-400",
        )}
        disabled={isLoading}
      >
        {!!selection || selection?.length ? (
          <span className="line-clamp-1 break-all">{triggerText}</span>
        ) : (
          "Select events"
        )}
        <CaretDown
          size={20}
          className="fill-black-950 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>
      <PopoverContent className="z-aboveDialog w-[--radix-popper-anchor-width] py-2">
        <SearchInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onClear={() => setInputValue("")}
          className="mx-4 mb-1"
          placeholder="Search events"
        />

        <div className="flex max-h-44 flex-col overflow-y-auto scrollbar-thin">
          {filteredEvents.map((event) => {
            const eventType = typeof event === "string" ? event : event.type;
            const isChecked =
              isMultiSelect === true
                ? !!selection.find((type) => type === eventType)
                : selection === event;

            function onClick() {
              if (isMultiSelect === true) {
                setSelection(
                  isChecked
                    ? selection.filter((type) => type !== eventType)
                    : [...selection, eventType],
                );
              } else {
                setSelection(eventType);
                setIsOpen(false);
              }
            }

            return (
              <button
                type="button"
                className="my-1 inline-flex gap-x-1 px-4 py-2 text-button-14 transition-colors hover:bg-black-100"
                key={eventType}
                onClick={onClick}
              >
                {isMultiSelect && (
                  <Checkbox
                    checked={isChecked}
                    isStyleOnly
                    className="m-[3.5px]"
                  />
                )}
                <div className="flex flex-col gap-0.5">
                  <h5 className="text-left text-button-14">{eventType}</h5>
                  {typeof event !== "string" && (
                    <p className="text-left text-caption-12-regular">
                      {event.description}
                    </p>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
}
