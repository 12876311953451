import {
  object,
  pipe,
  maxLength,
  string,
  trim,
  nonEmpty,
  url,
  array,
  InferOutput,
} from "valibot";

export const ApiKeyNameSchema = object({
  name: pipe(
    string(),
    trim(),
    nonEmpty("Key name is required"),
    maxLength(254, "Key name must be 254 characters or less"),
  ),
});

export const WhiteLabelNameSchema = object({
  name: pipe(
    string(),
    trim(),
    nonEmpty("Name is required"),
    maxLength(254, "Name must be 254 characters or less"),
  ),
});

export const WebhookFormSchema = object({
  name: pipe(
    string(),
    trim(),
    nonEmpty("Webhook name is required"),
    maxLength(254, "Webhook name must be 254 characters or less"),
  ),
  url: pipe(
    string(),
    nonEmpty("Webhook URL is required"),
    url("Enter a valid URL format (e.g., https://example.com)"),
  ),
  events: pipe(array(string()), nonEmpty("Select at least one event")),
});

export const ProtectedWebhookFormSchema = object({
  ...WebhookFormSchema.entries,
  headers: object({
    name: pipe(string(), trim(), nonEmpty("Header name is required")),
    value: pipe(string(), trim(), nonEmpty("Header value is required")),
  }),
});

export type WebhookFormType = InferOutput<
  typeof WebhookFormSchema | typeof ProtectedWebhookFormSchema
>;
