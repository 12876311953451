import React from "react";
import { X } from "@phosphor-icons/react";

import { Campaign } from "common/types";

interface CampaignBadgeProps {
  campaign: Campaign;
  removeFilter: (accountId: string) => void;
}

export default function CampaignBadge({
  campaign,
  removeFilter,
}: CampaignBadgeProps) {
  const { id, name } = campaign;

  return (
    <div className="flex w-fit items-center gap-x-1.5 rounded-lg bg-black-950 p-1.5 pl-3 text-white">
      <span className="text-button-12">{name}</span>

      <button
        type="button"
        aria-label="Remove filter icon"
        onClick={() => removeFilter(id)}
      >
        <X />
      </button>
    </div>
  );
}
