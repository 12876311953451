import React from "react";
import { CaretDown, UserList } from "@phosphor-icons/react";
import { clsx } from "clsx";

import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "common/components/ui/DropdownMenu";
import Checkbox from "common/components/ui/Checkbox";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

interface WorkspaceAccountSelectorProps {
  selectedAccountIds: string[];
  setSelectedAccountIds: (selectedAccountIds: string[]) => void;
  className?: string;
  showAllSelectedAccount?: boolean;
}

export default function WorkspaceAccountSelector({
  selectedAccountIds,
  setSelectedAccountIds,
  className = "",
  showAllSelectedAccount = false,
}: WorkspaceAccountSelectorProps) {
  const { accounts } = useSelectedWorkspaceContext();

  function removeAccountId(accountId: string) {
    setSelectedAccountIds(selectedAccountIds.filter((id) => id !== accountId));
  }
  function addAccountId(accountId: string) {
    setSelectedAccountIds([...selectedAccountIds, accountId]);
  }
  let triggerLeftIcon = <UserList />;
  let triggerLabel = "All accounts";

  if (
    selectedAccountIds.length &&
    (showAllSelectedAccount
      ? selectedAccountIds.length > 0
      : selectedAccountIds.length !== accounts.length)
  ) {
    triggerLeftIcon = (
      <div className="flex flex-row -space-x-1.5 rtl:space-x-reverse">
        {selectedAccountIds.slice(0, 3).map((selectedAccountId) => {
          const account = accounts.find(({ id }) => id === selectedAccountId);

          return (
            <ProfileImage
              key={account.id}
              size="xs"
              className="rounded-full ring-1 ring-purple-50"
              src={account.picture_url}
            />
          );
        })}
      </div>
    );

    triggerLabel =
      selectedAccountIds.length > 1
        ? `${selectedAccountIds.length} Accounts`
        : accounts.find(({ id }) => id === selectedAccountIds[0]).full_name;
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className={clsx("group", className)}
          variant="tertiary-black"
          leftIcon={triggerLeftIcon}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
        >
          <span className="mr-auto truncate">{triggerLabel}</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="z-aboveDialog max-h-[325px] w-full min-w-[--radix-dropdown-menu-trigger-width] overflow-y-auto scrollbar-thin md:max-h-[250px]"
        align="end"
      >
        <DropdownMenuItem
          className="gap-x-1"
          onSelect={() => {
            setSelectedAccountIds([]);
          }}
        >
          All accounts
        </DropdownMenuItem>

        {accounts.map(({ id, full_name, picture_url, license }) => {
          const isAccountIdIncluded = selectedAccountIds.includes(id);

          return (
            <DropdownMenuItem
              key={id}
              className="gap-x-1"
              onSelect={() => {
                setSelectedAccountIds([id]);
              }}
            >
              <Checkbox
                checked={isAccountIdIncluded}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => {
                  if (isAccountIdIncluded) {
                    removeAccountId(id);
                  } else {
                    addAccountId(id);
                  }
                }}
                size="sm"
              />

              <ProfileImage
                size="2xs"
                className={clsx(
                  "ml-2 rounded-full",
                  license && "border border-purple-500 ring-1 ring-purple-500",
                )}
                src={picture_url}
              />

              {full_name}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
