import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { WhiteLabelSettings } from "../types";

async function getWhiteLabelSettings(
  workspaceId: string,
): Promise<WhiteLabelSettings> {
  return (await get(`workspaces/${workspaceId}/customizations/dashboard`))
    .customization;
}

export default function useWhiteLabelSettings() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { whiteLabelKeys } = getQueryKeys(workspaceId);

  const {
    data: whiteLabelSettings,
    isLoading: isLoadingWhiteLabelSettings,
    isError: isWhiteLabelSettingsError,
    refetch: refetchWhiteLabelSettings,
  } = useQuery({
    queryKey: whiteLabelKeys.settings(),
    queryFn: () => getWhiteLabelSettings(workspaceId),
  });

  return {
    whiteLabelSettings,
    isLoadingWhiteLabelSettings,
    isWhiteLabelSettingsError,
    refetchWhiteLabelSettings,
  };
}
