import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { PencilSimpleLine, Sparkle, Trash } from "@phosphor-icons/react";
import clsx from "clsx";

import { templateTypes } from "common/constants";
import { CreatedTemplate } from "common/types";
import { Button } from "common/components/ui/Button";
import ConditionalWrapper from "common/components/ConditionalWrapper";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import Preview from "./FlowTemplatePreview";
import TemplateDetails from "./TemplateDetails";
import useDetectLanguage from "../../../datahooks/useDetectLanguage";
import {
  FlowInmailTemplate,
  FlowTemplate as FlowTemplateType,
} from "../../../types";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";
import TemplateSelect from "./TemplateSelect";

interface FlowTemplateProps {
  flowTemplate: FlowTemplateType;
  setFlowTemplate: (flowTemplate: FlowTemplateType) => void;
  onRemove: () => void;
  toggleAi: () => void;
  hideRemoveButton?: boolean;
  messageDelayComponent?: React.ReactNode;
  messageDelayEditComponent?: React.ReactNode;
}

export default function FlowTemplate({
  flowTemplate,
  setFlowTemplate,
  onRemove,
  toggleAi,
  hideRemoveButton = null,
  messageDelayComponent = null,
  messageDelayEditComponent = null,
}: FlowTemplateProps) {
  const isDesktop = useTwBreakpoint("lg");
  const [isEditing, setIsEditing] = useState(false);
  const { canEditFlow, isFlowError, resetFlowError, flow } =
    useCampaignFlowContext();
  const { detectLanguage } = useDetectLanguage();

  const {
    type,
    message,
    ai: isAiEnabled,
    edited: isEdited,
    original_id: originalId,
  } = flowTemplate;

  const isDisabled = !originalId;

  const form = useForm<{ message: string; subject?: string }>({
    defaultValues: {
      message,
      subject: type === "INMAIL_TEMPLATE" && flowTemplate.subject,
    },
    resolver: valibotResolver(templateTypes[type].flowValidationSchema),
  });

  function selectTemplate(template: CreatedTemplate) {
    const finalFlowTemplate: FlowTemplateType = {
      ...flowTemplate,
      original_id: template.id,
      edited: false,
      message: template.message,
    };
    if (template.type === "INMAIL_TEMPLATE") {
      (finalFlowTemplate as FlowInmailTemplate).subject = template.subject;
    }

    setFlowTemplate(finalFlowTemplate);

    if (!flow.is_manual_language) {
      detectLanguage({ text: finalFlowTemplate.message });
    }
  }

  function onSelectTemplate(template: CreatedTemplate) {
    selectTemplate(template);
    form.reset({
      message: template.message,
      subject: template.type === "INMAIL_TEMPLATE" && template.subject,
    });

    // Remove from invalidFlows
    if (resetFlowError) {
      resetFlowError();
    }
  }

  function onSubmit(data: { message: string; subject?: string }) {
    const shouldUpdate =
      data.message !== message ||
      (type === "INMAIL_TEMPLATE" && data.subject !== flowTemplate.subject);
    if (isEditing && shouldUpdate) {
      setFlowTemplate({ ...flowTemplate, ...data, edited: true });
    }
    form.reset({
      ...data,
    });
    detectLanguage({ text: data.message });
    setIsEditing(false);
  }

  function discardEdit() {
    setIsEditing(false);
    form.reset();
  }

  function onSave() {
    form.handleSubmit(onSubmit)();
  }

  const templateSelectComponent = (
    <TemplateSelect
      selectedTemplateId={originalId}
      isEdited={isEdited}
      onChange={onSelectTemplate}
      templateType={type}
      isDisabled={!canEditFlow || isEditing}
      isError={isFlowError}
    />
  );

  const previewComponent = (
    <Preview
      templateType={type}
      isAiEnabled={isAiEnabled}
      message={message}
      subject={type === "INMAIL_TEMPLATE" && flowTemplate.subject}
    />
  );

  const aiToggleComponent = (
    <Button
      onClick={toggleAi}
      variant={flowTemplate.ai ? "gradient" : "tertiary-purple"}
      size={isDesktop ? "sm" : "md"}
      leftIcon={<Sparkle />}
      disabled={!flowTemplate || isDisabled || !canEditFlow}
    >
      AI enhance{flowTemplate.ai ? "d" : ""}
    </Button>
  );

  const templateDetailsComponent = (
    <TemplateDetails
      isEditing={isEditing}
      aiToggleComponent={aiToggleComponent}
      flowTemplate={flowTemplate}
      isDisabled={isDisabled}
      form={form}
    />
  );

  const canShowRemoveButton = !(
    hideRemoveButton || flowTemplate.type === "INMAIL_TEMPLATE"
  );
  const removeButtonComponent = (
    <RenderIf condition={canShowRemoveButton}>
      <Button
        variant="tertiary-danger"
        className="ml-auto max-lg:w-full lg:-mt-2"
        leftIcon={<Trash />}
        onClick={onRemove}
        disabled={!canEditFlow}
      >
        Remove
      </Button>
    </RenderIf>
  );

  const isEditDisabled = isDisabled || !canEditFlow;
  const editButtonComponent = (
    <Button
      onClick={() => setIsEditing(true)}
      variant="secondary-purple"
      disabled={isEditDisabled}
      leftIcon={<PencilSimpleLine />}
    >
      Edit
    </Button>
  );

  if (isDesktop)
    return (
      <>
        <div className="grid grid-cols-2 gap-6">
          {/* TemplateDetails */}
          <div className="flex flex-1 flex-col">
            {messageDelayEditComponent}
            <div
              className={clsx(
                "mb-4 flex items-center gap-4",
                !!messageDelayEditComponent && "mt-4",
              )}
            >
              {templateSelectComponent}
              {isEditing ? (
                <div className="flex items-center gap-2">
                  <Button variant="tertiary-black" onClick={discardEdit}>
                    Discard
                  </Button>
                  <Button variant="secondary-purple" onClick={onSave}>
                    Save
                  </Button>
                </div>
              ) : (
                editButtonComponent
              )}
            </div>
            {templateDetailsComponent}
          </div>

          {/* Preview */}
          <ConditionalWrapper
            condition={!!messageDelayComponent}
            renderWrapper={(children) => (
              <div className="flex flex-col gap-4">{children}</div>
            )}
          >
            <RenderIf condition={!!messageDelayComponent}>
              {messageDelayComponent}
            </RenderIf>
            {previewComponent}
          </ConditionalWrapper>
        </div>

        {removeButtonComponent}
      </>
    );

  return (
    <>
      <div className="flex flex-col gap-4">
        {messageDelayEditComponent}
        <div className="flex items-center gap-2">
          {templateSelectComponent}
          {editButtonComponent}
        </div>
        {previewComponent}
        {aiToggleComponent}
        {removeButtonComponent}
      </div>
      <Drawer
        open={isEditing}
        onOpenChange={setIsEditing}
        onAfterClose={() => form.reset()}
      >
        <DrawerContent>
          {templateDetailsComponent}
          <div className="mt-12 flex flex-col gap-2">
            <Button onClick={onSave}>Save</Button>
            <Button variant="tertiary-black" onClick={discardEdit}>
              Discard
            </Button>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}
