import {
  BooleanParam,
  JsonParam,
  NumberParam,
  QueryParamConfigMap,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import React, { useEffect, useState } from "react";
import { ArrowCounterClockwise, FunnelSimple } from "@phosphor-icons/react";

import { typedObjectEntries } from "common/helpers/utils";
import Pagination from "common/components/ui/Pagination";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import LeadPreview from "common/components/LeadPreview";
import useAppStore from "common/hooks/useAppStore";

import useLeads from "../../datahooks/useLeads";
import { defaultLeadsSearchParams } from "../../constants";
import { FacetParam, LeadsSearchParams, SelectedFacetParam } from "../../types";
import MobileFilters from "./MobileFilters";
import SearchFilters from "./SearchFilters";
import LeadsList from "./LeadsList";
import LeadsTable from "./LeadsTable";
import useLeadsSearchParams from "../../hooks/useLeadsSearchParams";
import SyncingBadge from "./SyncingTooltip";
import Heading from "./Heading";
import NoLeadsPlaceholder from "./NoLeadsPlaceholder";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";
import SyncingLeadsImage from "assets/images/empty-placeholders/syncing-leads.png";

const paramsConfig = typedObjectEntries(defaultLeadsSearchParams).reduce(
  (accumulator, [key, value]) => {
    if (typeof value === "boolean") {
      accumulator[key] = withDefault(BooleanParam, value);
    } else if (typeof value === "string") {
      accumulator[key] = withDefault(StringParam, value);
    } else {
      accumulator[key] = withDefault(JsonParam, value);
    }
    return accumulator;
  },
  {} as QueryParamConfigMap,
);

const pageSize = 8;
export default function AllLeads() {
  const isDesktop = useTwBreakpoint("lg");
  const [searchParams, setSearchParams] = useQueryParams(paramsConfig, {
    removeDefaultsFromUrl: true,
    updateType: "replaceIn",
  }) as [
    LeadsSearchParams<FacetParam>,
    (searchParams: Partial<LeadsSearchParams<SelectedFacetParam>>) => void,
  ];
  const { resetAllParams } = useLeadsSearchParams(
    searchParams,
    setSearchParams,
  );
  const [keywordInputValue, setKeywordInputValue] = useState(
    searchParams.keywords,
  );
  const [previewLeadId, setPreviewLeadId] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, 0), {
    removeDefaultsFromUrl: true,
    updateType: "replaceIn",
  });
  const [orderSort, setOrderSort] = useQueryParam<"asc" | "desc">(
    "order",
    null,
    {
      removeDefaultsFromUrl: true,
      updateType: "replaceIn",
    },
  );
  const {
    leads,
    facets,
    totalLeads,
    isSyncing,
    accountsSync,
    isFetchingLeads,
    leadsError,
    refetchLeads,
  } = useLeads({
    searchParams,
    page,
    order: orderSort,
    pageSize,
  });

  useEffect(() => {
    if (isSyncing) {
      const originalHeaderContent = useAppStore.getState().headerContent;

      const accountIds: string[] = [];
      Object.entries(accountsSync).forEach(([accountId, value]) => {
        if (value) {
          accountIds.push(accountId);
        }
      });

      useAppStore.getState().setHeaderContent({
        component: <SyncingBadge accountIds={accountIds} />,
      });
      return () => {
        useAppStore.getState().setHeaderContent(originalHeaderContent);
      };
    }
  }, [isSyncing]);

  useEffect(() => {
    setPage(0);
  }, [searchParams]);

  if (leadsError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display leads data"
        onRetry={refetchLeads}
      />
    );
  }

  const { keywords, ...filters } = searchParams;

  const hasFilters = Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length;
    }
    // Favorite filter is Boolean
    return value;
  });

  if (
    !isFetchingLeads &&
    (!leads || !leads.length) &&
    !keywords &&
    !hasFilters
  ) {
    return isSyncing ? (
      <EmptyPlaceholder
        imageSrc={SyncingLeadsImage}
        title="Your Leads are loading"
        subtitle="Leads are currently synchronizing with your LinkedIn account. This process can last up to 24h, depending on your number of connections"
      />
    ) : (
      <NoLeadsPlaceholder />
    );
  }

  const filtersComponent = (
    <SearchFilters
      facets={facets}
      accountsSync={accountsSync}
      isLoading={isFetchingLeads}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
    />
  );

  const MainComponent = isDesktop ? LeadsTable : LeadsList;

  return (
    <div className="flex flex-col lg:grow lg:flex-row">
      <div className="flex h-fit shrink-0 flex-col lg:mr-6 lg:w-[280px] xl:w-[326px]">
        {isDesktop ? (
          <div className="rounded-20 border border-black-200">
            <div className="flex items-center gap-x-2 border-b border-b-black-200 p-4">
              <Button variant="secondary-black" intent="labelIcon">
                <FunnelSimple />
              </Button>

              <span className="text-headline-lg-bold">Filters</span>

              {hasFilters && (
                <Button
                  variant="quaternary-danger"
                  className="ml-auto"
                  onClick={resetAllParams}
                >
                  Clear all
                </Button>
              )}
            </div>

            <div className="p-4 pt-2">{filtersComponent}</div>
          </div>
        ) : (
          <MobileFilters
            filtersComponent={filtersComponent}
            resetAllParams={hasFilters && resetAllParams}
          />
        )}
      </div>

      <div className="flex flex-col lg:w-2/3 lg:grow">
        <Heading
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          totalLeads={totalLeads}
          isLoading={isFetchingLeads}
          keywordInputValue={keywordInputValue}
          setKeywordInputValue={setKeywordInputValue}
        />
        {leads && !leads.length ? (
          <EmptyPlaceholder
            title="No results found"
            subtitle="There were no results found for your search. Try searching for something else"
            imageSrc={NoResultsImage}
            actionComponent={
              <Button
                leftIcon={<ArrowCounterClockwise />}
                variant="secondary-black"
                onClick={() => {
                  setKeywordInputValue("");
                  resetAllParams();
                }}
              >
                Clear search
              </Button>
            }
          />
        ) : (
          <>
            <MainComponent
              leads={leads}
              isLoading={isFetchingLeads}
              openPreview={({ id }) => {
                setPreviewLeadId(id);
                setIsPreviewOpen(true);
              }}
              orderSort={orderSort}
              setOrderSort={setOrderSort}
            />
            <LeadPreview
              lead={leads && leads.find(({ id }) => id === previewLeadId)}
              isOpen={isPreviewOpen}
              onClose={() => setIsPreviewOpen(false)}
            />
          </>
        )}

        {!isFetchingLeads && !leadsError && (
          <Pagination
            pagesCount={Math.ceil(totalLeads / pageSize)}
            setPage={setPage}
            selectedIndex={page}
          />
        )}
      </div>
    </div>
  );
}
