import { createContext, useContext } from "react";

import { DetailedCampaign } from "../../pages/campaigns/types";

const CampaignContext = createContext<DetailedCampaign>(null);

export const CampaignProvider = CampaignContext.Provider;

export function useCampaignContext() {
  return useContext(CampaignContext);
}
