import React, { useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  Check,
  PaperPlaneRight,
  Tag as TagIcon,
  X,
} from "@phosphor-icons/react";
import clsx from "clsx";
import TextareaAutosize from "react-textarea-autosize";

import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag/LinkedInAccountsTag";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useInsertText from "../../../hooks/useInsertText";
import SettingsPopover from "./SettingsPopover";
import EmojiSelector from "../../EmojiSelector";
import useConversationMessagesUpdates from "../../../datahooks/useConversationMessagesUpdates";
import { Conversation, ConversationMessage } from "../../../types";
import { inboxVariables } from "../../../constants";

// Remove emojis from a string
function removeEmojis(text: string): string {
  return text
    .replace(
      /[\u{1F300}-\u{1F9FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E0}-\u{1F1FF}]/gu,
      "",
    )
    .trim();
}

function loadCtrlToSend() {
  const savedCtrlToSend = localStorage.getItem("ctrlToSend");
  if (savedCtrlToSend) {
    return JSON.parse(savedCtrlToSend);
  }
  return true;
}

interface MessageFieldProps {
  conversation: Conversation;
  editMessage: ConversationMessage;
  cancelEdit: () => void;
  onSend: () => void;
  isSendingMessage: boolean;
  formReturn: UseFormReturn<{ message: string }>;
}

export default function MessageField({
  conversation,
  editMessage,
  cancelEdit,
  onSend,
  isSendingMessage,
  formReturn,
}: MessageFieldProps) {
  const { register, setValue, getValues, reset, handleSubmit, watch } =
    formReturn;
  const {
    conversation_urn: conversationUrn,
    participants,
    owner: accountId,
    last_message: lastMessage,
    connected: isConnected,
  } = conversation ?? {};

  const { accounts } = useSelectedWorkspaceContext();
  const account = accounts.find(({ id }) => id === accountId);

  const { updateMessage, isUpdatingMessage } = useConversationMessagesUpdates({
    conversationUrn,
  });

  const [ctrlToSend, setCtrlToSend] = useState(loadCtrlToSend());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const messageFieldRef = useRef<HTMLTextAreaElement>(null);
  const insertText = useInsertText(messageFieldRef, (string) =>
    setValue("message", string),
  );

  const prevEditMessageRef = useRef<ConversationMessage | null>(null);

  useEffect(() => {
    if (editMessage !== prevEditMessageRef.current) {
      reset({ message: editMessage?.body });
      prevEditMessageRef.current = editMessage;
    }
  }, [editMessage, reset]);

  async function onSave({ message }: { message: string }): Promise<void> {
    if (message.trim() !== editMessage.body) {
      await updateMessage({
        accountId,
        message,
        messageUrn: editMessage.urn,
      });
    }
    cancelEdit();
  }

  function onConfirm(): void {
    if (editMessage) {
      handleSubmit(onSave)();
      return;
    }
    onSend();
  }

  function onKeyDown(e: React.KeyboardEvent): void {
    if (e.key === "Enter") {
      if (e.ctrlKey || e.metaKey) {
        if (ctrlToSend) {
          onConfirm();
        } else {
          setValue("message", `${getValues("message")}\n`);
        }
      } else if (!ctrlToSend) {
        onConfirm();
        e.preventDefault();
      }
    }
  }
  const isEmptyMessage = !watch("message")?.trim();
  const isMessagingDisabled =
    (!participants[0].is_lead && !isConnected) ||
    lastMessage?.declined ||
    account?.state !== "LoggedIn";

  const mobileVariables = (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DrawerTrigger asChild>
        <Button
          variant="quaternary-black"
          size="sm"
          intent="iconOnly"
          disabled={isMessagingDisabled}
        >
          <TagIcon weight="fill" />
        </Button>
      </DrawerTrigger>

      <DrawerContent className="pb-6">
        <h3 className="text-headline-xl-bold">Variables</h3>

        <div className="my-4 h-px bg-black-200" />

        <span className="mb-4 text-black-500">
          Personalize your message with custom Variables
        </span>

        <div className="flex flex-row flex-wrap gap-2">
          {inboxVariables.map(({ label, getValue }) => {
            const value = getValue(participants[0]);

            if (!value) {
              return;
            }

            const cleanValue = removeEmojis(value);
            if (!cleanValue) {
              return;
            }

            return (
              <Tag
                asChild
                key={label}
                variant="quaternary"
                size="lg"
                className="transition-colors hover:bg-black-200"
              >
                <button
                  type="button"
                  onClick={() => {
                    insertText(`${cleanValue} `);
                    setIsDrawerOpen(false);
                  }}
                >
                  {label}
                </button>
              </Tag>
            );
          })}
        </div>
      </DrawerContent>
    </Drawer>
  );

  const desktopVariables = (
    <div className="flex flex-row gap-1">
      {inboxVariables.map(({ label, getValue }) => {
        const value = getValue(participants[0]);

        if (!value) {
          return;
        }

        const cleanValue = removeEmojis(value);
        if (!cleanValue) {
          return;
        }

        return (
          <Tag
            asChild
            key={label}
            variant={isMessagingDisabled ? "disabled" : "quaternary"}
            className="transition-colors hover:bg-black-200"
          >
            <button
              type="button"
              disabled={isMessagingDisabled}
              onClick={() => insertText(`${cleanValue} `)}
            >
              {label}
            </button>
          </Tag>
        );
      })}
    </div>
  );

  const senderComponent = (
    <>
      <span className="text-caption-12-regular text-black-500">Sender:</span>

      <LinkedInAccountsTag
        accountIds={[accountId]}
        isDisabled={isMessagingDisabled}
      />
    </>
  );

  const { ref, ...registerRest } = register("message");

  return (
    <div
      className={clsx(
        isMessagingDisabled
          ? "border-black-200"
          : "border-purple-200 has-[textarea:hover]:bg-whiteGray has-[textarea:hover]:text-body-14-bold",
        "flex w-full flex-col rounded-2xl border bg-white p-2 transition-all md:mx-4 md:my-2 md:w-[calc(100%_-_32px)]",
        "has-[textarea:hover:focus-within]:bg-white",
        "has-[textarea:focus-visible]:text-body-14-bold has-[textarea:focus-visible]:ring has-[textarea:focus-visible]:ring-purple-200",
      )}
    >
      <div className="flex gap-2">
        <TextareaAutosize
          minRows={2}
          maxRows={6}
          disabled={isMessagingDisabled}
          placeholder={!isMessagingDisabled && "Write a message here..."}
          onKeyDown={onKeyDown}
          className="flex-1 resize-none bg-transparent py-1 outline-none scrollbar-thin"
          ref={(el) => {
            ref(el);
            messageFieldRef.current = el;
          }}
          {...registerRest}
        />
        {editMessage ? (
          <div className="flex flex-row items-center gap-2">
            {/* Cancel edit changes */}
            <Button
              variant="secondary-black"
              size="sm"
              intent="iconOnly"
              aria-label="Cancel edit"
              disabled={isUpdatingMessage}
              onClick={() => {
                cancelEdit();
                reset({ message: "" });
              }}
            >
              <X />
            </Button>

            {/* Confirm edit changes */}
            <Button
              variant="secondary-purple"
              size="sm"
              intent="iconOnly"
              aria-label="Confirm edit"
              disabled={isUpdatingMessage}
              isLoading={isUpdatingMessage}
              onClick={() => {
                onConfirm();
                reset({ message: "" });
              }}
            >
              <Check />
            </Button>
          </div>
        ) : (
          <Button
            size="sm"
            variant="secondary-purple"
            intent={isTabletOrDesktop ? "default" : "iconOnly"}
            isLoading={isSendingMessage}
            disabled={isEmptyMessage || isMessagingDisabled}
            rightIcon={<PaperPlaneRight weight="bold" />}
            onClick={onSend}
          >
            {isTabletOrDesktop && "Send"}
          </Button>
        )}
      </div>
      {/* Sender details */}
      <div className="mt-2 hidden flex-row items-center justify-end gap-2 lg:flex xl:hidden">
        {senderComponent}
      </div>
      <div
        className={clsx([
          participants?.length <= 1 ? "justify-between" : "justify-end",
          "mt-2 flex flex-row items-center border-t border-black-200 pt-2",
        ])}
      >
        {/* Clickable Label Variables */}
        <RenderIf condition={participants?.length <= 1}>
          {isTabletOrDesktop ? desktopVariables : mobileVariables}
        </RenderIf>

        <div className="flex flex-row flex-wrap justify-end gap-1 md:gap-2">
          {/* Sender details */}

          <div className="flex flex-row items-center gap-2 lg:hidden xl:flex">
            {senderComponent}
          </div>

          {/* Emoji Selector & Settings Popover */}
          <div className="flex flex-row items-center gap-2 xl:pr-10">
            <EmojiSelector
              onEmojiSelection={(emoji) => insertText(emoji)}
              isDisabled={isMessagingDisabled}
            />

            <SettingsPopover
              ctrlToSend={ctrlToSend}
              setCtrlToSend={setCtrlToSend}
              isDisabled={isMessagingDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
