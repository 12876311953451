import React from "react";

import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import { Tag } from "common/components/ui/Tag";
import { formatPrice } from "common/helpers/utils";

import DownloadInvoice from "./DownloadInvoice";
import { BILLING_STATUSES } from "../../constants";
import { Invoice } from "../../types";
import { formatBillingDate } from "../../utils";

export default function DesktopTable({ invoices }: { invoices: Invoice[] }) {
  return (
    <Table variant="simple">
      <TableHeader>
        <tr>
          <TableHead>Date</TableHead>
          <TableHead>Plan</TableHead>
          <TableHead>Amount</TableHead>
          <TableHead>Status</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      <tbody>
        {invoices.map(({ id, date, total, line_items, status }) => (
          <TableRow key={id}>
            <TableCell>{formatBillingDate(date * 1000)}</TableCell>

            <TableCell className="text-body-14-bold text-black-700">
              {line_items[0]?.description}
            </TableCell>

            <TableCell className="text-body-14-bold text-black-700">
              {formatPrice(total / 100)}
            </TableCell>

            <TableCell>
              <Tag variant="success">{BILLING_STATUSES[status].label}</Tag>
            </TableCell>

            <TableCell>
              <DownloadInvoice invoiceId={id} />
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}
