import { useQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Campaign } from "common/types";

async function getCampaigns(
  workspaceId: string,
  isMember: boolean,
): Promise<Campaign[]> {
  const campaigns = (await get(`workspaces/${workspaceId}/campaigns`))
    .campaigns as Campaign[];

  return campaigns
    .filter(({ state }) => (isMember ? state !== "INIT" : true))
    .sort(
      (campaignA, campaignB) => campaignB.created_at - campaignA.created_at,
    );
}

export default function useCampaigns() {
  const { id: workspaceId, accounts, role } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const isMember = role === "member";

  const {
    data: campaigns,
    isLoading: isLoadingCampaigns,
    error: campaignsError,
    refetch: refetchCampaigns,
  } = useQuery<Campaign[]>({
    queryKey: campaignsKeys.list(),
    queryFn: () => getCampaigns(workspaceId, isMember),
    enabled: !!accounts.length,
  });

  return {
    campaigns,
    campaignsError,
    isLoadingCampaigns,
    refetchCampaigns,
  };
}
