import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, patch, del } from "common/helpers/HTTP";
import { Workspace } from "common/types";

import useWorkspaceCacheUpdate from "./useWorkspaceCacheUpdate";

// Create workspace request
async function createWorkspaceRequest(name: string): Promise<Workspace> {
  return (await post("workspaces", { name })).workspace;
}

// Edit workspace name request
async function editWorkspaceNameRequest(id: string, name: string) {
  await patch(`workspaces/${id}`, { name });
}

// Delete workspace request
async function deleteWorkspaceRequest(id: string): Promise<void> {
  await del(`workspaces/${id}`);
}

export default function useWorkspaceMutations() {
  const queryClient = useQueryClient();
  const { updateCachedWorkspace } = useWorkspaceCacheUpdate();

  const { mutateAsync: createWorkspace, isPending: isCreatingWorkspace } =
    useMutation({
      mutationFn: ({ name }: { name: string }) => createWorkspaceRequest(name),
      onSuccess: (newWorkspace) => {
        queryClient.setQueryData<Workspace[]>(
          ["workspaces"],
          (prevWorkspaces) => [...prevWorkspaces, newWorkspace],
        );
        toast.success("Workspace created");
      },
    });

  const { mutateAsync: deleteWorkspace, isPending: isDeletingWorkspace } =
    useMutation({
      mutationFn: ({ id }: { id: string }) => deleteWorkspaceRequest(id),
      onSuccess: (_, { id }) => {
        queryClient.setQueryData<Workspace[]>(
          ["workspaces"],
          (prevWorkspaces) =>
            prevWorkspaces.filter((workspace) => workspace.id !== id),
        );
        toast.success("Workspace deleted");
      },
    });

  const { mutateAsync: editWorkspaceName, isPending: isEditingWorkspaceName } =
    useMutation({
      mutationFn: ({ id, name }: { id: string; name: string }) =>
        editWorkspaceNameRequest(id, name),
      onSuccess: (_, { id, name }) => {
        updateCachedWorkspace(id, (draftWorkspace) => {
          draftWorkspace.name = name;
        });
        toast.success("Workspace name updated");
      },
    });

  return {
    createWorkspace,
    isCreatingWorkspace,
    deleteWorkspace,
    isDeletingWorkspace,
    editWorkspaceName,
    isEditingWorkspaceName,
  };
}
