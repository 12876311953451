import React, { useState } from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";
import { HexColorInput, HexColorPicker } from "react-colorful";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Label from "common/components/ui/Label";

import useWhiteLabelMutations from "../../datahooks/useWhiteLabelMutations";

interface PrimaryColorProps {
  color: string;
}

const title = "Primary color";

export default function PrimaryColor({ color }: PrimaryColorProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(color);

  const { updateWhiteLabel, isUpdatingWhiteLabel } = useWhiteLabelMutations();

  function onSave() {
    updateWhiteLabel({
      primary_color: primaryColor,
    }).then((settings) => {
      setIsOpen(false);
      setPrimaryColor(settings.primary_color);
    });
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <div className="flex items-center gap-x-3 border-b border-black-200 max-md:py-4 md:h-16 md:px-3">
      <div className="flex flex-1 flex-col max-md:gap-2 md:flex-row">
        <h4 className="text-black-600 md:w-full md:max-w-[224px]">{title}</h4>

        <div className="flex flex-1 items-center gap-2.5">
          <span
            className="inline-flex size-4 rounded border border-black-400"
            style={{
              backgroundColor: color,
            }}
          />
          <p>{color}</p>
        </div>
      </div>

      <Component
        open={isOpen}
        onOpenChange={setIsOpen}
        onAfterClose={() => setPrimaryColor(color)}
      >
        <Trigger>
          <Button variant="quaternary-black" intent="iconOnly">
            <PencilSimpleLine />
          </Button>
        </Trigger>
        <Content>
          <h3 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
            {title}
          </h3>

          <HexColorPicker
            color={primaryColor}
            onChange={setPrimaryColor}
            className="mx-auto"
            data-vaul-no-drag // Prevent drawer from closing when dragging the Color Picker
          />
          <Label htmlFor="primary-color" className="mb-1 mt-4">
            Color
          </Label>
          <HexColorInput
            id="primary-color"
            name="primary-color"
            color={primaryColor}
            onChange={setPrimaryColor}
            className="mb-8 w-full rounded-xl bg-black-100 px-3 py-4 font-manrope text-button-16 text-black-700 focus-visible:outline-purple-200"
          />

          <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
            <Button
              className="flex-1"
              size={isTabletOrDesktop ? "md" : "lg"}
              isLoading={isUpdatingWhiteLabel}
              onClick={onSave}
            >
              Save
            </Button>
            <Close asChild>
              <Button
                className="flex-1"
                variant="secondary-black"
                size={isTabletOrDesktop ? "md" : "lg"}
                disabled={isUpdatingWhiteLabel}
              >
                Cancel
              </Button>
            </Close>
          </div>
        </Content>
      </Component>
    </div>
  );
}
